// utils
import axiosFeaturesInstance, { endpoints } from './axios';

const getReceiver = async (id) => {
  try {
    const response = await axiosFeaturesInstance(endpoints.qrPayment.getReceiver, {
      params: { id },
    });
    return response;
  } catch (error) {
    console.error('Error during get receiver data:', error);
    throw error;
  }
};

const getMyWallets = async () => {
  try {
    const response = await axiosFeaturesInstance(endpoints.qrPayment.myWallets);
    return response;
  } catch (error) {
    console.error('Error during get wallets:', error);
    throw error;
  }
};

const createTransfer = async (data) => {
  try {
    const response = await axiosFeaturesInstance.post(endpoints.qrPayment.createTransfer, data);
    return response;
  } catch (error) {
    console.error('Error when making qr payment:', error);
    throw error;
  }
};



export const qrPayment = { getReceiver, getMyWallets, createTransfer };
