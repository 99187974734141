import { Helmet } from 'react-helmet-async';
// sections
import WalletForm from '../components/wallet-form';

// ----------------------------------------------------------------------

export default function CreateWalletPage() {
  return (
    <>
      <Helmet>
        <title> Crear Billetera </title>
      </Helmet>

      <WalletForm />
    </>
  );
}
