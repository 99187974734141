import axios from 'axios';

const getLatLngFromAddress = async (address) => {
  const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  const geocodingUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
    address
  )}&key=${apiKey}`;
  try {
    const response = await axios.get(geocodingUrl);
    const { data } = response;
    if (data.status === 'OK') {
      const { location } = data.results[0].geometry;
      const latitude = location.lat;
      const longitude = location.lng;
      return { latitude, longitude };
      // eslint-disable-next-line no-else-return
    } else {
      console.error('Geocoding request failed');
      return null;
    }
  } catch (error) {
    console.error('Error while fetching geocoding data', error);
    throw error;
  }
};

export const googleMaps = { getLatLngFromAddress };
