import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
import * as wvg from 'webviewgold-sdk';
// @mui
import { useTheme } from '@mui/material/styles';
import { Stack, Box, Fab, Typography, Container, Divider, Button, Skeleton } from '@mui/material';
// components
import { useSnackbar } from 'src/commons/components/snackbar';
import Carousel, { useCarousel } from 'src/commons/components/carousel';
import { RouterLink } from 'src/routes/components';
import Avatar from 'src/commons/components/avatar';
import Iconify from 'src/commons/components/iconify';
// hooks
import { useAuthContext } from 'src/commons/contexts/auth/hooks';
// services
import { services } from 'src/services/api';
// utils
import { useParams } from 'src/routes/hook';
// assets
import { AvatarShape } from 'src/assets/illustrations';
import { paths } from 'src/routes/paths';
import Section from 'src/features/home/layout/section';
import StoreWallet from '../components/store-wallet';

// ----------------------------------------------------------------------

export default function StoreDetails() {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  const params = useParams();
  const { id } = params;
  const { user } = useAuthContext();
  const [store, setStore] = useState(null);
  const [isLoading, setIsLoading] = useState(null);

  const carousel = useCarousel({
    infinite: false,
    speed: 250,
    // centerMode: true,
    slidesToShow: 1,
    variableWidth: true,
  });

  useEffect(() => {
    if (!(window.location.hostname === 'localhost')) {
      wvg.setStatusBarColor('44,204,106');
    }

    return () => {
      if (!(window.location.hostname === 'localhost')) {
        wvg.setStatusBarColor('255,255,255');
      }
    };
  }, []);

  useEffect(() => {
    const fetchStore = async () => {
      try {
        setIsLoading(true);
        const res = await services.features.myStores.getById(id);
        if (res.success) setStore(res?.data);
      } catch (error) {
        setIsLoading(false);
        const errorMessage = typeof error === 'string' ? error : error.message;
        enqueueSnackbar(errorMessage, { variant: 'error' });
      } finally {
        setIsLoading(false);
      }
    };

    fetchStore();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const renderAvatar = (
    <Box sx={{ position: 'relative', mb: 4 }}>
      <AvatarShape
        sx={{
          left: 0,
          right: 0,
          zIndex: 10,
          mx: 'auto',
          bottom: -26,
          position: 'absolute',
        }}
      />

      <Avatar
        sx={{
          width: 52,
          height: 52,
          zIndex: 11,
          left: 0,
          right: 0,
          bottom: -32,
          backgroundColor: 'grey.100',
          mx: 'auto',
          position: 'absolute',
        }}
      >
        <Iconify icon="ant-design:shop-outlined" width={32} sx={{ color: 'common.black' }} />
      </Avatar>

      <Container
        sx={{
          height: 1,
          background: theme.palette.green.primary,
          display: 'flex',
          alignItems: 'flex-start',
          p: 2,
          justifyContent: 'space-between',
        }}
      >
        <Fab
          size="small"
          variant="soft"
          sx={{ width: 32, height: 32, minHeight: 32, backgroundColor: theme.palette.grey[100] }}
          color={theme.palette.grey[100]}
          aria-label="go-back"
          component={RouterLink}
          href={paths.home.stores.root}
        >
          <Iconify sx={{ color: theme.palette.green.dark }} icon="ic:round-close" width={22} />
        </Fab>
      </Container>

      <Box height={25} sx={{}} />
    </Box>
  );

  return (
    <>
      <Helmet>
        <title> {store?.name || 'Detalles local'} </title>
      </Helmet>
      <Stack
        component="main"
        direction="column"
        spacing={2}
        sx={{
          height: '100vh',
          background: theme.palette.background.default,
        }}
      >
        {renderAvatar}
        <Stack direction="column" alignItems="center">
          <Typography
            variant="h5"
            component="div"
            fontSize={28}
            sx={{
              color: 'green.dark',
              textAlign: 'center',
              textTransform: 'capitalize',
            }}
          >
            {isLoading ? <Skeleton width={200} height={28} sx={{ mb: 0.5 }} /> : store?.name}
          </Typography>
          <Typography
            variant="caption"
            component="div"
            fontSize={13}
            sx={{
              color: 'grey.500',
              textAlign: 'center',
              textTransform: 'capitalize',
            }}
          >
            Responsable:{' '}
            <Box
              component="span"
              sx={{ color: 'grey.700' }}
            >{`${user?.name} ${user?.lastname}`}</Box>
          </Typography>
        </Stack>

        <Section title="Billeteras asociadas" sx={{ pl: 2 }}>
          {isLoading ? (
            <Box sx={{ mr: 2 }}>
              <Skeleton
                animation="wave"
                sx={{ borderRadius: 1.5, width: 1 }}
                variant="rectangular"
                height={80}
              />
            </Box>
          ) : (
            <Carousel ref={carousel.carouselRef} {...carousel.carouselSettings}>
              {store?.wallets?.map((wallet) => (
                <StoreWallet key={wallet?._id} wallet={wallet} />
              ))}
            </Carousel>
          )}
        </Section>
        <Stack direction="column" spacing={1} sx={{ width: 1 }} flexGrow={1}>
          <Section title="Dirección" sx={{ px: 2 }} spacing={1}>
            <Typography
              variant="caption"
              component="div"
              fontSize={13}
              sx={{
                color: 'grey.700',
                textTransform: 'capitalize',
                mb: 1,
              }}
            >
              {isLoading ? <Skeleton width={300} height={13} sx={{ mb: 0.5 }} /> : store?.address}
            </Typography>
            <Divider light />
          </Section>
          <Section title="Rubro" sx={{ px: 2 }} spacing={1}>
            <Typography
              variant="caption"
              component="div"
              fontSize={13}
              sx={{
                color: 'grey.700',
                textTransform: 'capitalize',
                mb: 1,
              }}
            >
              {isLoading ? (
                <Skeleton width={300} height={13} sx={{ mb: 0.5 }} />
              ) : (
                store?.fields?.join(',')
              )}
            </Typography>
            <Divider light />
          </Section>
          <Section title="Numero de teléfono" sx={{ px: 2 }} spacing={1}>
            <Typography
              variant="caption"
              component="div"
              fontSize={13}
              sx={{
                color: 'grey.700',
                textTransform: 'capitalize',
                mb: 1,
              }}
            >
              {isLoading ? <Skeleton width={300} height={13} sx={{ mb: 0.5 }} /> : store?.phone}
            </Typography>
            <Divider light />
          </Section>
          <Section title="Descripción" sx={{ px: 2 }} spacing={1}>
            <Typography
              variant="caption"
              component="div"
              fontSize={13}
              sx={{
                color: 'grey.700',
                textTransform: 'capitalize',
                mb: 1,
              }}
            >
              {isLoading ? (
                <Skeleton width={300} height={13} sx={{ mb: 0.5 }} />
              ) : (
                store?.description
              )}
            </Typography>
          </Section>
        </Stack>
        <Box sx={{ px: 2, pb: 3, mt: 4, width: 1 }}>
          <Button
            component={RouterLink}
            href={paths.home.stores.edit(id)}
            fullWidth
            type="submit"
            color="primary"
            variant="contained"
            size="large"
          >
            Editar Local
          </Button>
        </Box>
      </Stack>
    </>
  );
}
