import PropTypes from 'prop-types';
// @mui
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
// theme
import { bgGradient } from 'src/config/theme/css';
// hooks
import { useBoolean } from 'src/commons/hooks/use-boolean';
//
import Main from './main';
import Header from './header';
import NavVertical from './nav-vertical';
import AppBar from '../components/app-bar/app-bar';

// ----------------------------------------------------------------------

export default function HomeLayout({ children }) {
  const theme = useTheme();

  const nav = useBoolean();

  const renderNavVertical = <NavVertical openNav={nav.value} onCloseNav={nav.onFalse} />;

  return (
    <>
      <Header onOpenNav={nav.onTrue} />

      <Box
        sx={{
          minHeight: 1,
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          ...bgGradient({
            startColor: theme.palette.green.primary,
            endColor: theme.palette.green.dark,
          }),
        }}
      >
        {renderNavVertical}

        <Main sx={{ pb: 0 }}>{children}</Main>
        <AppBar />
      </Box>
    </>
  );
}

HomeLayout.propTypes = {
  children: PropTypes.node,
};
