import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// components
import { LoadingScreen } from 'src/commons/components/loading-screen';
import WrapperLayout from 'src/commons/layouts/wrapper/layout';
import StoreDetailsPage from 'src/features/stores-and-promos/pages/store-details';
import { paths } from '../paths';

// ----------------------------------------------------------------------

// OVERVIEW
const IndexPage = lazy(() => import('src/features/stores-and-promos'));

// ----------------------------------------------------------------------

export const storesAndPromosRoutes = [
  {
    path: '/stores-and-promos',
    element: (
      <Suspense fallback={<LoadingScreen />}>
        <Outlet />
      </Suspense>
    ),
    children: [
      {
        element: (
          <WrapperLayout title="Buscá locales" path={paths.home.root}>
            <IndexPage />
          </WrapperLayout>
        ),
        index: true,
      },
      { path: ':id', element: <StoreDetailsPage /> },
    ],
  },
];
