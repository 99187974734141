// utils
import axiosFeaturesInstance, { endpoints } from './axios';

const list = async () => {
  try {
    const response = await axiosFeaturesInstance.get(endpoints.stores.list);
    return response;
  } catch (error) {
    console.error('Error during list stores:', error);
    throw error;
  }
};

export const stores = { list };
