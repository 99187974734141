import * as wvg from 'webviewgold-sdk';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
// @mui
import { useTheme } from '@mui/material/styles';
import { Stack, Typography, Button, TextField, Box } from '@mui/material';
import { inputBaseClasses } from '@mui/material/InputBase';
// components
import Header from 'src/commons/layouts/wrapper/header';
// eslint-disable-next-line import/no-extraneous-dependencies
import { QRCodeSVG } from 'qrcode.react';
// hooks
import { useAuthContext } from 'src/commons/contexts/auth/hooks';
import Iconify from 'src/commons/components/iconify';
// assets

// ----------------------------------------------------------------------

export default function MyQR() {
  const theme = useTheme();
  const [name, setName] = useState('');
  const { user } = useAuthContext();

  useEffect(() => {
    if (!(window.location.hostname === 'localhost')) {
      wvg.setStatusBarColor('6,32,19');
    }

    return () => {
      if (!(window.location.hostname === 'localhost')) {
        wvg.setStatusBarColor('255,255,255');
      }
    };
  }, []);

  const maxLength = 20;
  const remaining = maxLength - String(name).length;
  const helperText = String(name).length
    ? `Caracteres disponibles: ${remaining}/${maxLength}`
    : `Este Nombre aparecerá cuando alguien escanee tu QR`;

  let QR_URL = `https://qr.palta.app/?id=${user?.id}`;
  QR_URL = name ? `${QR_URL}&name=${name}` : QR_URL;

  return (
    <>
      <Helmet>
        <title> Mi QR </title>
      </Helmet>
      <Box
        component="main"
        sx={{
          height: 1,
          pt: 0,
        }}
      >
        <Header
          title="Mi QR"
          sx={{
            backgroundColor: 'green.dark',
            // color: 'common.white',
          }}
        />
        <Stack
          direction="column"
          justifyContent="center"
          sx={{
            position: 'absolute',
            inset: 0,
            minHeight: 1,
            pb: 3,
            px: 2,
            backgroundColor: 'green.dark',
            color: 'common.white',
          }}
        >
          <Stack spacing={3} justifyContent="center" textAlign="center" flexGrow={1}>
            <Typography variant="h2" sx={{ fontWeight: 700, textTransform: 'capitalize' }}>
              {user?.name} {user?.lastname}
            </Typography>
            <QRCodeSVG
              value={QR_URL}
              bgColor={theme.palette.green.dark}
              fgColor={theme.palette.common.white}
              level="H"
              style={{ margin: '0 auto', width: '80%', height: 'auto' }}
              includeMargin={false}
            />
            <Typography variant="body2" sx={{ fontWeight: 500 }} textAlign="center">
              Mostrá este QR para que lo escaneen en los comercios adheridos de palta o puedan
              enviarte dinero
            </Typography>
            <TextField
              placeholder="Ingresa el nombre de tu QR"
              helperText={
                <Typography variant="caption" sx={{ color: 'common.white' }} textAlign="left">
                  {helperText}
                </Typography>
              }
              variant="outlined"
              value={name}
              sx={{
                [`& .${inputBaseClasses.input}`]: {
                  color: 'black',
                },
              }}
              inputProps={{ maxLength, style: { backgroundColor: 'white', borderRadius: '8px' } }}
              onChange={(e) => setName(e.currentTarget.value)}
            />
          </Stack>
          <Button
            variant="contained"
            size="large"
            fullWidth
            startIcon={<Iconify icon="ph:file-pdf" color={theme.palette.common.white} />}
          >
            Descargar QR
          </Button>
        </Stack>
      </Box>
    </>
  );
}
