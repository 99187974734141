import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// mui
import IconButton from '@mui/material/IconButton';
// icons
import Iconify from 'src/commons/components/iconify';
// auth
import { AuthGuard } from 'src/commons/contexts/auth/guard';
// components
import { RouterLink } from 'src/routes/components';
import { LoadingScreen } from 'src/commons/components/loading-screen';
import WrapperLayout from 'src/commons/layouts/wrapper/layout';
import OperationDetailsPage from 'src/features/operations/pages/operation-details';

// ----------------------------------------------------------------------

export const operationsRoutes = [
  {
    path: '/operations',
    element: (
      <AuthGuard>
        <Suspense fallback={<LoadingScreen />}>
          <Outlet />
        </Suspense>
      </AuthGuard>
    ),
    children: [
      {
        path: ':id',
        element: (
          <WrapperLayout
            title="Detalle de operación"
            actions={
              <IconButton
                component={RouterLink}
                href="/more/need-help"
                sx={{
                  p: 0.25,
                  borderRadius: 1.5,
                  width: 32,
                  height: 32,
                }}
              >
                <Iconify width={32} sx={{ color: 'common.black' }} icon="bx:support" />
              </IconButton>
            }
          >
            <OperationDetailsPage />
          </WrapperLayout>
        ),
      },
    ],
  },
];
