import { useState, useEffect, useMemo } from 'react';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
// routes
import { paths } from 'src/routes/paths';
import { useRouter, useParams } from 'src/routes/hook';
// components
import { useSnackbar } from 'src/commons/components/snackbar';
import FormProvider, { RHFTextField } from 'src/commons/components/hook-form';
// services
import { services } from 'src/services/api';
// utils
import WalletItem from './wallet-item';
import WalletSkeleton from './wallet-skeleton';

// ----------------------------------------------------------------------

export default function EditWalletForm() {
  const params = useParams();
  const { id } = params;
  const router = useRouter();

  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState(true);
  const [wallet, setWallet] = useState(null);

  const EditWalletSchema = Yup.object().shape({
    name: Yup.string().max(16).required('El nombre es requerido'),
  });

  const defaultValues = useMemo(
    () => ({
      name: '',
    }),
    []
  );

  const methods = useForm({
    resolver: yupResolver(EditWalletSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    const fetchWallet = async () => {
      try {
        setIsLoading(true);
        const res = await services.features.wallets.getById(id);
        if (res.success) setWallet(res?.data);
      } catch (err) {
        console.error('Error fetching wallet information', err);
      } finally {
        setIsLoading(false);
      }
    };
    fetchWallet();
  }, [id]);

  const onSubmit = handleSubmit(async (data) => {
    try {
      const res = await services.features.wallets.editWalletName(id, data.name);
      if (res.success) {
        reset();
        enqueueSnackbar('Nombre actualizado con éxito');
        router.push(paths.home.wallets.root);
      }
    } catch (error) {
      console.error(error);
    }
  });

  return (
    <FormProvider fullHeight methods={methods} onSubmit={onSubmit}>
      <Stack direction="column" justifyContent="space-between" sx={{ height: 1 }}>
        <Grid container spacing={3}>
          <Grid xs={12} md={4}>
            {isLoading ? <WalletSkeleton /> : <WalletItem wallet={wallet} />}
          </Grid>

          <Grid xs={12} md={8}>
            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
              }}
            >
              <RHFTextField
                required
                name="name"
                label="Nombre"
                helperText={<Typography variant="caption">Máximo 16 caracteres</Typography>}
              />
            </Box>
          </Grid>
        </Grid>
        <Stack sx={{ pb: 2 }}>
          <LoadingButton
            type="submit"
            color="primary"
            variant="contained"
            size="large"
            fullWidth
            loading={isSubmitting}
          >
            Guardar
          </LoadingButton>
        </Stack>
      </Stack>
    </FormProvider>
  );
}
