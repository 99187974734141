// mui
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Button from '@mui/material/Button';
// components
import Iconify from 'src/commons/components/iconify';

function WalletDetailsSkeleton() {
  return (
    <Stack direction="column" spacing={1} alignItems="center">
      <Box
        alignItems="center"
        justifyContent="center"
        sx={{
          borderRadius: 1.5,
          display: 'flex',
          backgroundColor: 'grey.100',
          mb: 1,
          width: 56,
          height: 56,
        }}
      >
        <Skeleton variant="circular" width={32} height={32} />
      </Box>
      <Skeleton variant="text" width={100} height={20} />
      <Skeleton variant="text" width={150} height={32} />
      <Stack direction="row" spacing={1.5} sx={{ width: 1 }}>
        <Button
          fullWidth
          variant="contained"
          color="secondary"
          size="medium"
          sx={{ fontSize: '14px', fontWeight: 'fontWeightBold' }}
          startIcon={<Iconify icon="majesticons:arrow-down" />}
        >
          Retirar
        </Button>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          size="medium"
          sx={{ fontSize: '14px', fontWeight: 'fontWeightBold' }}
          startIcon={<Iconify icon="bi:qr-code-scan" />}
        >
          Pagar
        </Button>
      </Stack>
    </Stack>
  );
}

export default WalletDetailsSkeleton;
