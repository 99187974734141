import PropTypes from 'prop-types';
import { useEffect, useReducer, useCallback, useMemo } from 'react';

// utils
import axios, { endpoints } from 'src/utils/axios';
// services
import { services } from 'src/services/api';
//
import { paths } from 'src/routes/paths';
import { AuthContext } from './auth-context';
import { generateFingerprint, isValidToken, jwtDecode, setSession } from './utils';

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

const initialState = {
  user: null,
  loading: true,
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      loading: false,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGIN') {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === 'REGISTER') {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGOUT') {
    return {
      ...state,
      user: null,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

const STORAGE_KEY = 'accessToken';

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const initialize = useCallback(async () => {
    try {
      const accessToken = localStorage.getItem(STORAGE_KEY);

      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);
        const token = jwtDecode(accessToken);
        const res = await services.api.auth.getUser(token.cuit);
        localStorage.setItem('cuit', token?.cuit);
        const user = { ...res?.data, lastname: token?.lastname };
        dispatch({
          type: 'INITIAL',
          payload: {
            isAuthenticated: true,
            user,
          },
        });

        const { exp } = jwtDecode(accessToken);
        const currentTime = Math.floor(Date.now() / 1000);
        if (exp - currentTime <= 300) {
          await refreshToken();
        }
      } else {
        dispatch({
          type: 'INITIAL',
          payload: {
            user: null,
          },
        });
      }

      let fingerprint = localStorage.getItem('fingerprint');
      if (!fingerprint) {
        fingerprint = await generateFingerprint();
        localStorage.setItem('fingerprint', fingerprint);
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: 'INITIAL',
        payload: {
          user: null,
        },
      });
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // REFRESH TOKEN
  const refreshToken = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_AUTH_API_URL}v1/auth/refresh`);
      const jwtToken = response.data.data.jwt;
      const user = jwtDecode(jwtToken);
      setSession(jwtToken);

      dispatch({
        type: 'LOGIN',
        payload: {
          user,
        },
      });
    } catch (error) {
      console.error('Failed to refresh token:', error);
      localStorage.removeItem('accessToken');
      window.location.href = paths.auth.login;
    }
  };

  // LOGIN
  const login = useCallback(async (userId, phoneOtp, emailOtp) => {
    const response = await services.api.auth.login(userId, phoneOtp, emailOtp);
    const { jwt } = response.data;

    setSession(jwt);
    const user = jwtDecode(jwt);
    dispatch({
      type: 'LOGIN',
      payload: {
        user,
      },
    });
  }, []);

  // REGISTER
  const register = useCallback(async (email, password, firstName, lastName) => {
    const data = {
      email,
      password,
      firstName,
      lastName,
    };

    const response = await axios.post(endpoints.auth.register, data);

    const { accessToken, user } = response.data;

    localStorage.setItem(STORAGE_KEY, accessToken);

    dispatch({
      type: 'REGISTER',
      payload: {
        user,
      },
    });
  }, []);

  // LOGOUT
  const logout = useCallback(async () => {
    setSession(null);
    dispatch({
      type: 'LOGOUT',
    });
  }, []);

  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      method: 'jwt',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      //
      login,
      register,
      logout,
    }),
    [login, logout, register, state.user, status]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.node,
};
