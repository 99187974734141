import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// components
import { LoadingScreen } from 'src/commons/components/loading-screen';
// Configurations
import Configuration from 'src/features/more/pages/configuration';
import PersonalData from 'src/features/more/pages/configuration/personal-data';
import ChangePhone from 'src/features/more/pages/configuration/change-phone';
import DeleteAccount from 'src/features/more/pages/configuration/delete-account';
// Legals
import Legals from 'src/features/more/pages/legals';
// Help
import Help from 'src/features/more/pages/help';
import Supports from 'src/features/more/pages/help/supports';
import CreateComplaint from 'src/features/more/pages/help/create-complaint';
// My QR
import MyQR from 'src/features/more/pages/my-qr';

// ----------------------------------------------------------------------

// OVERVIEW
const IndexPage = lazy(() => import('src/features/more'));

// ----------------------------------------------------------------------

export const moreRoutes = [
  {
    path: '/more',
    element: (
      <Suspense fallback={<LoadingScreen />}>
        <Outlet />
      </Suspense>
    ),
    children: [
      {
        element: <IndexPage />,
        index: true,
      },
      { path: '/more/configurations', element: <Configuration /> },
      { path: '/more/configurations/personal-data', element: <PersonalData /> },
      { path: '/more/configurations/change-phone', element: <ChangePhone /> },
      { path: '/more/configurations/delete-account', element: <DeleteAccount /> },
      { path: '/more/legals/terms-and-conditions', element: <Legals /> },
      { path: '/more/need-help', element: <Help /> },
      { path: '/more/need-help/supports', element: <Supports /> },
      { path: '/more/need-help/create-complaint', element: <CreateComplaint /> },
      { path: '/more/my-qr', element: <MyQR /> },
    ],
  },
];
