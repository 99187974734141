import axios from 'axios';
import { FEATURES_API } from 'src/config-global';

const axiosFeaturesInstance = axios.create({ baseURL: FEATURES_API });

axiosFeaturesInstance.interceptors.response.use(
  (response) => response.data,
  (error) => {
    // Handle error globally or rethrow for individual handling
    const errorResponse = error.response && error.response.data;
    console.error('Error in API request:', errorResponse || 'Something went wrong');
    return Promise.reject(errorResponse || 'Something went wrong');
  }
);

export const fetcher = async (args) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosFeaturesInstance.get(url, { ...config });

  return res.data;
};

export default axiosFeaturesInstance;

export const endpoints = {
  onboarding: {
    create: '/features/v1/onboarding',
    updateStatus: '/features/v1/onboarding',
  },
  storesAndPromos: {
    list: '/features/v1/stores-and-promos/stores',
    listPromos: '/features/v1/stores-and-promos/promos',
    getPromo: '/features/v1/stores-and-promos/promos',
    getStore: '/features/v1/stores-and-promos/stores',
  },
  history: {
    list: '/features/v1/history',
  },
  notifications: {
    list: '/features/v1/notifications',
    count: '/features/v1/home/unread-notifications',
  },
  operations: {
    list: '/features/v1/operations',
  },
  wallets: {
    list: '/features/v1/my-wallets',
    hideWallet: '/features/v1/my-wallets/hide-wallet',
    editWalletName: '/features/v1/my-wallets/change-name-wallet',
  },
  promotions: {
    list: '/features/v1/home/promos',
  },
  stores: {
    list: '/features/v1/home/stores',
  },
  cashin: {
    list: '/features/v1/cashin',
    updateAlias: '/features/v1/cashin/update-alias',
  },
  phoneTopup: {
    listWallets: '/features/v1/phone-topup/wallets',
    getOptions: '/features/v1/phone-topup/options',
    getCompany: '/features/v1/phone-topup/company',
    pay: '/features/v1/phone-topup',
  },
  qrPayment: {
    getReceiver: '/features/v1/qr-payment/receiver',
    myWallets: '/features/v1/qr-payment/my-wallets',
    createTransfer: '/features/v1/qr-payment',
  },
  servicePayment: {
    listWallets: '/features/v1/service-payment/wallets',
    getBill: '/features/v1/service-payment/bill',
    getCompany: '/features/v1/service-payment/company',
    getOptions: '/features/v1/service-payment/options',
    getCompanyParameters: '/features/v1/service-payment/parameters',
    pay: '/features/v1/service-payment',
  },
  sendMoney: {
    allRecentExternalAccounts: '/features/v1/send-money/all-external-accounts',
    externalAccount: '/features/v1/send-money/external-account',
    paltaAccount: '/features/v1/send-money/palta-account',
    recentAccounts: '/features/v1/send-money/recent-accounts',
    search: '/features/v1/send-money/search-accounts',
    wallets: '/features/v1/send-money/wallets',
  },
  more: {
    root: '/features/v1/more',
  },
  myStores: {
    root: `/features/v1/stores`,
  },
  configs: {
    listFields: `/features/v1/configs/fields`,
  },
  webPush: {
    getPublicVapidKey: `/features/v1/web-push/publickey`,
    createSubscription: `/features/v1/web-push/subscriptions`,
    removeSubscription: `/features/v1/web-push/subscriptions`,
  },
};
