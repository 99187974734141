import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/commons/contexts/auth/guard';
// components
import { LoadingScreen } from 'src/commons/components/loading-screen';
import HistoryLayout from 'src/features/history/layout';
import CreateWalletPage from 'src/features/wallets/pages/create-wallet';
import WrapperLayout from 'src/commons/layouts/wrapper/layout';
import SuccessfullyWalletCreatedPage from 'src/features/wallets/pages/successfully-wallet-created';
import WalletDetailsPage from 'src/features/wallets/pages/wallet-details';
import HideWalletPage from 'src/features/wallets/pages/hide-wallet';
import EditWalletPage from 'src/features/wallets/pages/edit-wallet';

// ----------------------------------------------------------------------

// OVERVIEW
const IndexPage = lazy(() => import('src/features/wallets'));

// ----------------------------------------------------------------------

export const walletsRoutes = [
  {
    path: '/wallets',
    element: (
      <AuthGuard>
        <Suspense fallback={<LoadingScreen />}>
          <Outlet />
        </Suspense>
      </AuthGuard>
    ),
    children: [
      {
        element: (
          <HistoryLayout>
            <IndexPage />
          </HistoryLayout>
        ),
        index: true,
      },
      {
        path: 'create',
        element: (
          <WrapperLayout title="Abrir billetera">
            <CreateWalletPage />
          </WrapperLayout>
        ),
      },
      {
        path: 'successfully-created',
        element: <SuccessfullyWalletCreatedPage />,
      },
      { path: ':id', element: <WalletDetailsPage /> },
      {
        path: ':id/hide',
        element: (
          <WrapperLayout>
            <HideWalletPage />
          </WrapperLayout>
        ),
      },
      {
        path: ':id/edit',
        element: (
          <WrapperLayout title="Editar nombre">
            <EditWalletPage />
          </WrapperLayout>
        ),
      },
    ],
  },
];
