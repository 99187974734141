import PropTypes from 'prop-types';
// @mui
import Box from '@mui/material/Box';
import Header from './header';

// ----------------------------------------------------------------------

export default function WrapperLayout({ title, path, children, actions }) {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <Header title={title} path={path} actions={actions} />

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          pt: 0,
          px: 2,
          pb: 3,
        }}
      >
        {children}
      </Box>
    </Box>
  );
}

WrapperLayout.propTypes = {
  title: PropTypes.string,
  path: PropTypes.string,
  actions: PropTypes.node,
  children: PropTypes.node,
};
