// api/auth/index.js

import axiosAuthInstance, { endpoints } from './axios';

const login = async (userId, phoneOtp, emailOtp) => {
  const fingerprint = localStorage.getItem('fingerprint');
  try {
    const response = await axiosAuthInstance.post(endpoints.auth.login, {
      userId,
      phoneOtp,
      emailOtp,
      fingerprint,
    });
    return response;
  } catch (error) {
    console.error('Error during login:', error);
    throw error;
  }
};

const logout = async () => {
  try {
    const response = await axiosAuthInstance(endpoints.auth.logout);
    return response;
  } catch (error) {
    console.error('Error during logout:', error);
    throw error;
  }
};

const otp = async (userId, isEmail) => {
  const fingerprint = localStorage.getItem('fingerprint');
  try {
    const params = { userId, fingerprint };

    if (isEmail) {
      params.email = true;
    } else {
      params.phone = true;
    }

    const response = await axiosAuthInstance.get(endpoints.auth.otp, { params });
    return response;
  } catch (error) {
    console.error('Error during OTP request:', error);
    throw error;
  }
};

const refreshToken = async () => {
  try {
    const response = await axiosAuthInstance.post(endpoints.auth.refresh);
    return response;
  } catch (error) {
    console.error('Error during refresh token request:', error);
    throw error;
  }
};

const getUser = async (cuitOrDni) => {
  try {
    const response = await axiosAuthInstance(endpoints.auth.getUser, { params: { cuitOrDni } });
    return response;
  } catch (error) {
    console.error('Error during login:', error);
    throw error;
  }
};

export const auth = {
  login,
  logout,
  refreshToken,
  otp,
  getUser,
};
