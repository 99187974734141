// utils
import axiosFeaturesInstance, { endpoints } from './axios';

const listWallets = async () => {
  try {
    const response = await axiosFeaturesInstance(endpoints.phoneTopup.listWallets);
    return response;
  } catch (error) {
    console.error('Error during phone topup wallets list:', error);
    throw error;
  }
};

const getOptions = async (data) => {
  try {
    const response = await axiosFeaturesInstance.post(endpoints.phoneTopup.getOptions, data);
    return response;
  } catch (error) {
    console.error('Error during get options:', error);
    throw error;
  }
};

const getCompany = async (name) => {
  try {
    const response = await axiosFeaturesInstance(endpoints.phoneTopup.getCompany, {params: {name}});
    return response;
  } catch (error) {
    console.error('Error during get company:', error);
    throw error;
  }
};

const pay = async (data) => {
  try {
    const response = await axiosFeaturesInstance.post(endpoints.phoneTopup.pay, data);
    return response;
  } catch (error) {
    console.error('Error when applying cellular recharge:', error);
    throw error;
  }
};

export const phoneTopup = { listWallets, getOptions, getCompany, pay };
