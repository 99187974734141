import { PropTypes } from 'prop-types';
// @mui
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
// components
import Iconify from 'src/commons/components/iconify';
import Avatar from 'src/commons/components/avatar';
// ----------------------------------------------------------------------

export default function ActionableItem({
  arrowIcon = false,
  description,
  disabled = false,
  icon,
  onClick = () => {},
  title,
  color,
  bgcolor,
  sx,
}) {
  const theme = useTheme();

  const handleClick = () => {
    if (!disabled) {
      onClick();
    }
  };

  return (
    <Stack
      sx={{
        cursor: 'pointer',
        width: 1,
        '&:hover': {
          opacity: 0.85,
        },
        color: color || 'inherit',
        ...sx,
      }}
      alignItems="center"
      direction="row"
      onClick={handleClick}
      spacing={2}
    >
      <Avatar
        variant="rounded"
        sx={{
          alignSelf: 'flex-start',
          height: 40,
          width: 40,
          bgcolor: bgcolor || 'grey.100',
        }}
      >
        <Iconify
          width={25}
          sx={{
            color: color || theme.palette.green.dark,
          }}
          icon={icon}
        />
      </Avatar>

      <Box sx={{ flexGrow: 1, minWidth: 0 }} textAlign="start">
        <Typography variant="body1" component="p" sx={{ letterSpacing: 0.5, fontWeight: 600 }}>
          {title}
        </Typography>
        <Typography variant="caption" color="grey.600" sx={{ letterSpacing: 0.25 }}>
          {description}
        </Typography>
      </Box>
      <Stack alignItems="flex-end">
        {arrowIcon && (
          <Iconify
            width={32}
            sx={{ color: theme.palette.green.main }}
            icon="ic:baseline-chevron-right"
          />
        )}
      </Stack>
    </Stack>
  );
}

ActionableItem.propTypes = {
  arrowIcon: PropTypes.bool,
  description: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.string,
  onClick: PropTypes.func,
  title: PropTypes.string,
  color: PropTypes.string,
  bgcolor: PropTypes.string,
  sx: PropTypes.object,
};
