// utils
import axiosFeaturesInstance, { endpoints } from './axios';

const list = async () => {
  try {
    const queryParams = new URLSearchParams();
    const url = `${endpoints.notifications.list}?${queryParams.toString()}`;

    const response = await axiosFeaturesInstance.get(url);
    return response;
  } catch (error) {
    console.error('Error during list notifications:', error);
    throw error;
  }
};
const get = async (notificationId) => {
  try {
    const url = `${endpoints.notifications.list}/${notificationId}`;

    const response = await axiosFeaturesInstance.get(url);
    return response;
  } catch (error) {
    console.error('Error during list notifications:', error);
    throw error;
  }
};
const getUnreadNotifications = async () => {
  try {
    const url = `${endpoints.notifications.count}/`;
    const response = await axiosFeaturesInstance.get(url);
    return response;
  } catch (error) {
    console.error('Error during list notifications:', error);
    throw error;
  }
};

export const notifications = { list, get, getUnreadNotifications };
