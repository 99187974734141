import { createContext, useState, useMemo } from 'react';
import { useNavigate } from 'react-router';
import PropTypes from 'prop-types';
import { paths } from 'src/routes/paths';

const StepContext = createContext({
  handleNext: () => {},
  handleBack: () => {},
});

export const StepLoginProvider = ({ children }) => {
  const [activeStep, setActiveStep] = useState(0);
  const navigate = useNavigate()

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    if (activeStep === 0) navigate(paths.home.root)
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const value = useMemo(() => ({
    activeStep,
    handleNext,
    handleBack,
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [activeStep]);

  return (
    <StepContext.Provider value={value}>
      {children}
    </StepContext.Provider>
  );
};

StepLoginProvider.propTypes = {
  children: PropTypes.node
};

export default StepContext;
