import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';
// @mui
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Badge from '@mui/material/Badge';

import Stack from '@mui/material/Stack';
// components
import Avatar from 'src/commons/components/avatar';
import Iconify from 'src/commons/components/iconify';
import { fCurrency } from 'src/utils/format-number';
import { convertDate, getCoinImage } from './utils';

// ----------------------------------------------------------------------

const parseURL = (url, operationTitle, operationCurrency) => {
  if (url.includes('/operations')) {
    const match = url.match(/\/operations\/(\w+)/);
    if (match && match[1]) {
      return `/operations/${match[1]}?title=${operationTitle}&currency=${operationCurrency}`;
    }
  }
  return '#';
};

// ----------------------------------------------------------------------
export default function MovementItem({ movement }) {
  const { title, type, subtitle, counterparty, date, currency, amount, url } = movement;
  const _navigate = useNavigate();

  const navigate = (URL, operationTitle, operationCurrency) => {
    const newUrl = parseURL(URL, operationTitle, operationCurrency);
    if (newUrl !== '#') {
      _navigate(newUrl);
    }
  };

  return (
    <Box onClick={() => navigate(url, title, currency)}>
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
        sx={{ width: 1, borderBottom: 0.5, borderColor: 'grey.100', pb: 1.25, my: 1 }}
      >
        <Badge
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          overlap="circular"
          badgeContent={
            <Iconify
              icon={`ic:baseline-arrow-${type === 'income' ? 'upward' : 'downward'}`}
              sx={{
                color: `${type === 'income' ? 'green.primary' : 'error.main'}`,
                backgroundColor: 'common.white',
                borderRadius: 10,
              }}
            />
          }
        >
          <Avatar
            variant="rounded"
            alt={`${counterparty?.name} ${counterparty?.lastname}`}
            img={counterparty?.avatar || ''}
          />
        </Badge>

        <Stack direction="column" justifyContent="flex-start" alignItems="flex-start" flexGrow={1}>
          <Typography
            variant="body1"
            fontWeight={700}
            sx={{
              color: 'grey.850',
              textTransform: 'capitalize',
            }}
          >
            {title}
          </Typography>
          <Typography
            variant="caption"
            sx={{
              color: 'grey.500',
              textTransform: 'capitalize',
            }}
          >
            {subtitle} ·{' '}
            <Box component="span" sx={{ color: 'grey.700', fontWeight: 700 }}>
              {convertDate(date)}
            </Box>
          </Typography>
        </Stack>

        <Stack direction="row" spacing={1} justifyContent="flex-end" alignItems="center">
          <Box component="img" src={getCoinImage(currency)} />
          <Typography
            variant="body1"
            fontWeight={700}
            sx={{
              color: 'grey.850',
            }}
          >
            {fCurrency({ number: amount, withSymbol: false })}
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
}

MovementItem.propTypes = {
  movement: PropTypes.object,
};
