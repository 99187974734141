import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/commons/contexts/auth/guard';
// components
import { LoadingScreen } from 'src/commons/components/loading-screen';
import HistoryLayout from 'src/features/history/layout';

// ----------------------------------------------------------------------

// OVERVIEW
const IndexPage = lazy(() => import('src/features/history'));

// ----------------------------------------------------------------------

export const historyRoutes = [
  {
    path: '/history',
    element: (
      <AuthGuard>
        <HistoryLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </HistoryLayout>
      </AuthGuard>
    ),
    children: [{ element: <IndexPage />, index: true }],
  },
];
