import PropTypes from 'prop-types';
// @mui
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

// ----------------------------------------------------------------------

export default function Section({ title, children, sx = {}, titleSx = {}, spacing = 2 }) {
  return (
    <Box component="section" width="100%" sx={{ py: 1, ...sx }}>
      <Stack direction="row" spacing={spacing} justifyContent="space-between" sx={{ pb: spacing }}>
        <Typography fontWeight={700} variant="subtitle1" sx={{ color: 'green.dark', ...titleSx }}>
          {title}
        </Typography>
      </Stack>
      {children}
    </Box>
  );
}

Section.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  sx: PropTypes.object,
  titleSx: PropTypes.object,
  spacing: PropTypes.number,
};
