import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack, Box, Typography, Button } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
// components
import { useSnackbar } from 'src/commons/components/snackbar';
import FormProvider, { RHFTextField, RHFAutocomplete } from 'src/commons/components/hook-form';
// services
import { services } from 'src/services/api';
// assets
import Header from 'src/commons/layouts/wrapper/header';

// ----------------------------------------------------------------------

const TextBox = ({ label = '', value = '-' }) => (
  <Box
    sx={{
      display: 'flex-inline',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      p: 2,
      border: 1,
      borderRadius: 1,
      borderColor: 'grey.400',
    }}
  >
    <Typography variant="body2" sx={{ fontWeight: 700, letterSpacing: 0.25 }}>
      {label}
    </Typography>
    <Typography variant="body2" sx={{ color: 'grey.600' }}>
      {value}
    </Typography>
  </Box>
);
TextBox.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
};

// ----------------------------------------------------------------------

const categories = [
  'No uso la cuenta',
  'Mala experiencia',
  'No entiendo como funciona',
  'No me sirve',
  'No me gusta',
  'Otro',
];

// ----------------------------------------------------------------------

export default function Configurations() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const NewComplaintSchema = Yup.object().shape({
    category: Yup.string().required('Debe seleccionar una categoria'),
    text: Yup.string().min(30).required('texto requerido'),
  });

  const defaultValues = useMemo(
    () => ({
      category: '',
      text: '',
    }),
    []
  );

  const methods = useForm({
    resolver: yupResolver(NewComplaintSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      const res = await services.features.more.configurations.deleteAccount(data);
      if (res.success) {
        reset();
        enqueueSnackbar('Pedido de eliminación de cuenta enviado');
        navigate(-1);
      }
    } catch (error) {
      enqueueSnackbar('Error al crear el pedido de eliminación de cuenta', { variant: 'error' });
      console.error(error);
    }
  });

  return (
    <>
      <Helmet>
        <title> Eliminar cuenta </title>
      </Helmet>

      <Header title="Eliminar cuenta" />
      <Typography variant="h6" sx={{ px: 2, mt: 2, fontWeight: 500, color: 'grey.500' }}>
        Contanos el motivo por el cual decidís eliminar tu cuenta en Palta, ayúdanos a mejorar
      </Typography>
      <Stack direction="column" justifyContent="space-between" px={2} mt={5} spacing={2.5}>
        <FormProvider fullHeight methods={methods} onSubmit={onSubmit}>
          <Stack direction="column" spacing={2.5} flexGrow={1}>
            <RHFAutocomplete
              required
              name="category"
              label="Selecciona una categoria"
              options={categories}
              getOptionLabel={(option) => option}
              isOptionEqualToValue={(option, value) => option === value}
            />
            <RHFTextField
              multiline
              minRows={5}
              required
              name="text"
              label="¿Cuál es el motivo del reclamo?"
            />
          </Stack>
          <Stack
            sx={{ px: 2, pb: 3, position: 'absolute', bottom: 0, left: 0, width: 1 }}
            spacing={2}
          >
            <LoadingButton
              type="submit"
              color="error"
              variant="contained"
              size="large"
              fullWidth
              loading={isSubmitting}
            >
              Eliminar cuenta
            </LoadingButton>
            <Button
              variant="outlined"
              color="error"
              size="large"
              fullWidth
              onClick={() => navigate(-1)}
            >
              Cancelar
            </Button>
          </Stack>
        </FormProvider>
      </Stack>
    </>
  );
}
