import * as wvg from 'webviewgold-sdk';
import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTheme } from '@mui/material/styles';
import { m, useAnimation } from 'framer-motion';
// mui
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Alert,
  Button,
  Box,
  Stack,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
// hooks
import { useBoolean } from 'src/commons/hooks/use-boolean';
import { useAuthContext } from 'src/commons/contexts/auth/hooks';
// routes
import { useRouter } from 'src/routes/hook';
// components
import FormProvider, { RHFCode } from 'src/commons/components/hook-form';
import NumericPad from 'src/commons/components/numpad/numpad';

export default function UnlockForm() {
  const storedConfirmedPin = JSON.parse(localStorage.getItem('confirmedPinCode'));
  const dialogForgotPIN = useBoolean();
  const { user } = useAuthContext();
  const dialog = useBoolean();
  const router = useRouter();
  const theme = useTheme();
  const controls = useAnimation();
  const [failedAttempts, setFailedAttempts] = useState(0);

  useEffect(() => {
    if (!(window.location.hostname === 'localhost')) {
      if (dialog.value || dialogForgotPIN.value) {
        wvg.setStatusBarColor('31,63,50');
      } else {
        wvg.setStatusBarColor('44,204,106');
      }
    }
  }, [dialog.value, dialogForgotPIN.value]);

  const shakeAnimation = {
    x: [0, -10, 10, -10, 10, 0],
    transition: { duration: 0.5 },
  };
  const startShake = async () => {
    await controls.start(shakeAnimation);
    controls.start({ x: 0 });
  };

  const defaultValues = { pin: '' };
  const methods = useForm({
    mode: 'onChange',
    delayError: 500,
    defaultValues,
  });

  const { handleSubmit, watch, setValue } = methods;

  const [error, setError] = useState(false);
  const isPinComplete = watch('pin').length === 6;

  const validatePin = (enteredPin) => {
    if (enteredPin === storedConfirmedPin) {
      setFailedAttempts(0);
      const unlockTimestamp = Date.now().toString();
      sessionStorage.setItem('unlockTimestamp', unlockTimestamp);
      router.replace('/');
    } else {
      setValue('pin', '');
      setError(true);
      startShake();
      setFailedAttempts(failedAttempts + 1);
      setTimeout(() => {
        setError(false);
      }, 1000);
      if (failedAttempts === 3) {
        handleLogout();
      }
    }
  };

  const onSubmit = handleSubmit((data) => {
    const enteredPin = data.pin;
    validatePin(enteredPin);
  });

  const validateChar = (value, event) => {
    if (event?.key === 'Backspace') {
      return true;
    }

    if (value.length === 0) {
      return true;
    }

    const isNumber = typeof value === 'number';
    const isString = typeof value === 'string';
    return (isNumber || (isString && value !== '')) && !Number.isNaN(Number(value));
  };

  const handleNumberClick = (number) => {
    const newValue = methods.getValues('pin') + number;
    methods.setValue('pin', newValue);
    if (newValue.length === 6) {
      validatePin(newValue);
    }
  };

  const handleDeleteClick = () => {
    const pinValue = methods.getValues('pin');
    const newValue = pinValue.slice(0, -1);
    methods.setValue('pin', newValue);
  };

  const handleLogout = () => {
    localStorage.removeItem('accessToken');
    sessionStorage.removeItem('unlockTimestamp');
    window.location.href = '/';
  };

  return (
    <FormProvider fullHeight methods={methods} onSubmit={onSubmit}>
      <Stack sx={{ pt: 4, height: 1 }} alignItems="center">
        <Stack sx={{ width: 1 }} spacing={2} flexGrow={1} alignItems="center">
          {failedAttempts ? (
            <Alert severity="error" sx={{ width: 1, mb: 2, fontWeight: 600 }}>
              {failedAttempts === 1 ? 'PIN incorrecto. Te quedan 2 intentos' : null}
              {failedAttempts === 2 ? 'PIN incorrecto. Te queda 1 intento' : null}
              {failedAttempts === 3
                ? 'PIN incorrecto. Si vuelves a equivocarte, se cerrará la sesión'
                : null}
              {failedAttempts === 4 ? 'Se cerrará la sesión' : null}
            </Alert>
          ) : (
            <Typography sx={{ color: 'grey.800', fontWeight: 600 }}>Ingresá tu PIN</Typography>
          )}
          <m.div animate={controls} style={{ display: 'inline-block' }}>
            <RHFCode
              disabled
              validateChar={validateChar}
              name="pin"
              TextFieldsProps={{ placeholder: '-', type: 'password', disabled: true }}
              inputProps={{
                type: 'password',
                cursor: 'none',
                outline: 'none',
                caretColor: 'transparent',
                fontSize: 40,
              }}
              sx={{
                '& input': {
                  textAlign: 'center',
                  caretColor: 'transparent',
                  fontSize: 40,
                  color: error ? 'error.main' : '',
                },
                '& input::placeholder': {
                  color: error ? 'error.main' : '',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  borderStyle: 'none',
                },
                '& .MuiOutlinedInput-notchedOutline:focus': {
                  borderStyle: 'none',
                  fontColor: 'common.white',
                },
                '& .MuiInputBase-input.Mui-disabled': {
                  WebkitTextFillColor: '#000000',
                },
              }}
            />
          </m.div>
        </Stack>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ width: 1, my: 2, color: 'common.white' }}
          spacing={2}
        >
          <Grid xs={6}>
            <Button
              fullWidth
              onClick={() => dialogForgotPIN.onTrue()}
              color="inherit"
              sx={{
                fontWeight: theme.typography.fontWeightMedium,
                textAlign: 'center',
                fontSize: 16,
              }}
              variant="soft"
              size="large"
            >
              Olvidé mi PIN
            </Button>
          </Grid>
          <Grid xs={6}>
            <Button
              fullWidth
              onClick={dialog.onTrue}
              color="inherit"
              sx={{
                fontWeight: theme.typography.fontWeightMedium,
                textAlign: 'center',
                fontSize: 16,
              }}
              variant="soft"
              size="large"
            >
              Cerrar Sesión
            </Button>
          </Grid>
        </Grid>
        <NumericPad
          disabled={isPinComplete}
          onNumberClick={handleNumberClick}
          onDeleteClick={handleDeleteClick}
        />
      </Stack>
      {/* Cerrar sesión */}
      <Dialog open={dialog.value} onClose={dialog.onFalse}>
        <DialogTitle>¿Desea cerrar la sesión?</DialogTitle>

        <DialogContent>
          <Typography sx={{ mb: 3 }}>
            ¡No te vayas{' '}
            <Box component="span" sx={{ fontWeight: 'bolder', textTransform: 'capitalize' }}>
              {user?.name ? String(user?.name) : ''}
            </Box>{' '}
            😢 te vamos a extrañar!
          </Typography>
          <Typography sx={{ mb: 3 }}>
            Despues para iniciar sesión tendras que volver a pedir los códigos de seguridad que
            enviamos a tu email y teléfono.
          </Typography>
        </DialogContent>

        <DialogActions>
          <Button onClick={dialog.onFalse} variant="outlined" color="inherit">
            Cancelar
          </Button>
          <Button onClick={handleLogout} variant="contained">
            Continuar
          </Button>
        </DialogActions>
      </Dialog>
      {/* Olvidé mi PIN */}
      <Dialog open={dialogForgotPIN.value} onClose={dialogForgotPIN.onFalse}>
        <DialogTitle>¿Olvidaste tu PIN?</DialogTitle>

        <DialogContent>
          <Typography sx={{ mb: 3 }}>
            Tu PIN es un código de 6 dígitos que solo vos conoces.
          </Typography>
          <Typography sx={{ mb: 3 }}>
            Si olvidaste tu PIN, tendrás que volver a iniciar sesión.
          </Typography>
        </DialogContent>

        <DialogActions>
          <Button onClick={dialogForgotPIN.onFalse} variant="contained">
            Entendido
          </Button>
        </DialogActions>
      </Dialog>
    </FormProvider>
  );
}
