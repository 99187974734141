import { Navigate, useRoutes } from 'react-router-dom';
// config
import { PATH_AFTER_LOGIN } from 'src/config-global';
//
import UnlockPage from 'src/pages/auth/jwt/unlock';
// guard
import { authRoutes } from './auth';
import { homeRoutes } from './home';
import { storesAndPromosRoutes } from './stores-and-promos';
import { notificationsRoutes } from './notifications';
import { historyRoutes } from './history';
import { walletsRoutes } from './wallets';
import { chargeYourPaltaRoutes } from './charge-your-palta';
import { updateAliasRoutes } from './update-alias';
import { phoneTopupRoutes } from './phone-topup';
import { qrPaymentRoutes } from './qr-payment';
import { operationsRoutes } from './operations';
import { sendMoneyRoutes } from './send-money';
import { moreRoutes } from './more';
import { servicePaymentRoutes } from './service-payment';
import { storesRoutes } from './stores';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <Navigate to={PATH_AFTER_LOGIN} replace />,
    },

    // ----------------------------------------------------------------------

    {
      path: 'unlock',
      element: <UnlockPage />,
    },

    // Auth routes
    ...authRoutes,

    // Home routes
    ...homeRoutes,

    // Stores and Promos routes
    ...storesAndPromosRoutes,

    // Operations routes
    ...operationsRoutes,

    // Notifications routes
    ...notificationsRoutes,

    // History routes
    ...historyRoutes,

    // Wallets routes
    ...walletsRoutes,

    // Charge Your Palta routes
    ...chargeYourPaltaRoutes,

    // Update Alias
    ...updateAliasRoutes,

    // Phone Topup
    ...phoneTopupRoutes,

    // Qr Payment
    ...qrPaymentRoutes,

    // Send Money
    ...sendMoneyRoutes,

    // More
    ...moreRoutes,

    // Service Payment
    ...servicePaymentRoutes,

    // Stores
    ...storesRoutes,

    // No match 404
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
