// utils
import axiosFeaturesInstance, { endpoints } from './axios';

const list = async () => {
  try {
    const queryParams = new URLSearchParams();
    const url = `${endpoints.operations.list}?${queryParams.toString()}`;

    const response = await axiosFeaturesInstance.get(url);
    return response;
  } catch (error) {
    console.error('Error during list operations:', error);
    throw error;
  }
};
const get = async (operationId) => {
  try {
    const url = `${endpoints.operations.list}/${operationId}`;

    const response = await axiosFeaturesInstance.get(url);
    return response;
  } catch (error) {
    console.error('Error during list operations:', error);
    throw error;
  }
};

export const operations = { list, get };
