import { useEffect } from 'react';
import * as wvg from 'webviewgold-sdk';
// @mui
import { useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Avatar from 'src/commons/components/avatar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
// hooks
import { useAuthContext } from 'src/commons/contexts/auth/hooks';
// assets
import { AvatarShape } from 'src/assets/illustrations';
import UnlockForm from './components/unlock-form';

// ----------------------------------------------------------------------

export default function UnlockView() {
  const theme = useTheme();
  const { user } = useAuthContext();

  useEffect(() => {
    if (!(window.location.hostname === 'localhost')) {
      wvg.setStatusBarColor('44,204,106');
    }

    return () => {
      if (!(window.location.hostname === 'localhost')) {
        wvg.setStatusBarColor('255,255,255');
      }
    };
  }, []);

  const renderAvatar = (
    <Box sx={{ position: 'relative', mb: 6 }}>
      <AvatarShape
        sx={{
          left: 0,
          right: 0,
          zIndex: 10,
          mx: 'auto',
          bottom: -26,
          position: 'absolute',
        }}
      />

      <Avatar
        alt={`${user?.name} ${user?.lastname}`}
        src={user?.avatar}
        sx={{
          width: 64,
          height: 64,
          zIndex: 11,
          left: 0,
          right: 0,
          bottom: -32,
          mx: 'auto',
          position: 'absolute',
        }}
      />
      <Box height={91} sx={{ background: theme.palette.green.primary }} />
    </Box>
  );

  return (
    <Stack
      component="main"
      direction="column"
      justifyContent="space-between"
      sx={{
        height: '100vh',
        background: theme.palette.background.default,
      }}
    >
      {renderAvatar}
      <Typography
        variant="body1"
        component="div"
        sx={{
          mb: 0.5,
          color: theme.palette.grey[800],
          fontWeight: 900,
          textAlign: 'center',
          textTransform: 'capitalize',
        }}
      >
        {user?.name} {user?.lastname}
      </Typography>
      <Box
        sx={{
          flexGrow: 1,
          pt: 0,
          px: 2,
          pb: 3,
        }}
      >
        <UnlockForm />
      </Box>
    </Stack>
  );
}
