import { useEffect } from 'react';
import * as wvg from 'webviewgold-sdk';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Howl } from 'howler';
import { m } from 'framer-motion';
// @mui
import Box from '@mui/material/Box';
// assets
import { success, error } from './assets/illustrations';
import successSound from './assets/sounds/success.mp3';

// ----------------------------------------------------------------------

export default function StatusScreen({ sx, isSuccess = true, ...other }) {
  useEffect(() => {
    if (!(window.location.hostname === 'localhost')) {
      if (isSuccess) wvg.setStatusBarColor('41,204,106');
      if (!isSuccess) wvg.setStatusBarColor('252,85,85');
    }

    return () => {
      if (!(window.location.hostname === 'localhost')) {
        wvg.setStatusBarColor('255,255,255');
      }
    };
  }, [isSuccess]);

  useEffect(() => {
    const sound = new Howl({
      src: [successSound],
      volume: 0.5, // Volumen (0.0 - 1.0)
      loop: false, // Repetir el sonido
      autoplay: false, // Reproducir automáticamente cuando se crea el objeto de sonido
    });
    if (isSuccess) sound.play();
    return () => {
      sound.stop();
      sound.unload();
    };
  }, [isSuccess]);

  return (
    <Box
      sx={{
        right: 0,
        width: 1,
        bottom: 0,
        height: 1,
        zIndex: 9998,
        display: 'flex',
        position: 'fixed',
        alignItems: 'center',
        justifyContent: 'center',
        bgcolor: isSuccess ? 'green.primary' : 'error.main',
        ...sx,
      }}
      {...other}
    >
      <m.div
        initial={{ scale: 0, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0, opacity: 0 }}
        transition={{ type: 'spring', damping: 5, stiffness: 40, delay: 0.25 }}
      >
        <Box component="img" src={isSuccess ? success : error} alt="Success" />
      </m.div>
    </Box>
  );
}

StatusScreen.propTypes = {
  sx: PropTypes.object,
  isSuccess: PropTypes.bool,
};
