import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack, Box, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
// components
import { useSnackbar } from 'src/commons/components/snackbar';
import FormProvider, { RHFTextField, RHFAutocomplete } from 'src/commons/components/hook-form';
// services
import { services } from 'src/services/api';
// assets
import Header from 'src/commons/layouts/wrapper/header';

// ----------------------------------------------------------------------

const TextBox = ({ label = '', value = '-' }) => (
  <Box
    sx={{
      display: 'flex-inline',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      p: 2,
      border: 1,
      borderRadius: 1,
      borderColor: 'grey.400',
    }}
  >
    <Typography variant="body2" sx={{ fontWeight: 700, letterSpacing: 0.25 }}>
      {label}
    </Typography>
    <Typography variant="body2" sx={{ color: 'grey.600' }}>
      {value}
    </Typography>
  </Box>
);
TextBox.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
};

// ----------------------------------------------------------------------

const categories = [
  'Cuenta',
  'envio de dinero',
  'Recarga de celular',
  'Pago de servicios',
  'Pago con QR',
  'Inversiones',
  'Configuración',
  'Cerrar cuenta',
  'Otros',
];

// ----------------------------------------------------------------------

export default function Configurations() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const NewComplaintSchema = Yup.object().shape({
    title: Yup.string().required('Debe seleccionar una categoria'),
    message: Yup.string().max(200).required('texto requerido'),
  });

  const defaultValues = useMemo(
    () => ({
      title: '',
      message: '',
    }),
    []
  );

  const methods = useForm({
    resolver: yupResolver(NewComplaintSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      console.log('data', data);
      const res = await services.features.more.needHelp.createComplaint(data);
      if (res.success) {
        reset();
        enqueueSnackbar('Reclamo creado con éxito');
        navigate(-1);
      }
    } catch (error) {
      enqueueSnackbar('Error al crear el reclamo', { variant: 'error' });
      console.error(error);
    }
  });

  return (
    <>
      <Helmet>
        <title> ¿Con que te ayudamos? </title>
      </Helmet>
      <Header title="¿Con que te ayudamos?" />
      <Typography variant="h6" sx={{ px: 2, mt: 2, fontWeight: 500, color: 'grey.500' }}>
        Contanos o déjanos tu inquietud y nos pondremos en contacto
      </Typography>
      <Stack direction="column" justifyContent="space-between" px={2} mt={5} spacing={2.5}>
        <FormProvider fullHeight methods={methods} onSubmit={onSubmit}>
          <Stack direction="column" spacing={2.5}>
            <RHFAutocomplete
              required
              name="title"
              label="Selecciona una categoria"
              options={categories}
              getOptionLabel={(option) => option}
              isOptionEqualToValue={(option, value) => option === value}
            />
            <RHFTextField
              multiline
              minRows={5}
              required
              name="message"
              label="¿Cuál es el motivo del reclamo?"
            />
            <Box sx={{ px: 2, pb: 3, position: 'absolute', bottom: 0, left: 0, width: 1 }}>
              <LoadingButton
                type="submit"
                color="primary"
                variant="contained"
                size="large"
                fullWidth
                loading={isSubmitting}
              >
                Enviar
              </LoadingButton>
            </Box>
          </Stack>
        </FormProvider>
      </Stack>
    </>
  );
}
