// routes
import { paths } from 'src/routes/paths';
// utils
import axiosFeaturesInstance from 'src/services/api/features/axios';

// ----------------------------------------------------------------------

export const jwtDecode = (token) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join('')
  );

  return JSON.parse(jsonPayload);
};

// ----------------------------------------------------------------------

export const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }

  const decoded = jwtDecode(accessToken);

  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

// ----------------------------------------------------------------------
export const tokenExpired = (expiration) => {
  const currentTime = Math.floor(Date.now() / 1000);

  if (expiration < currentTime) {
    localStorage.removeItem('accessToken');
    alert('Token expired');
    window.location.href = paths.auth.login;
  }
};
// export const tokenExpired = (exp) => {
//   // eslint-disable-next-line prefer-const
//   let expiredTimer;

//   const currentTime = Date.now();

//   // Test token expires after 10s
//   // const timeLeft = currentTime + 10000 - currentTime; // ~10s
//   const timeLeft = exp * 1000 - currentTime;

//   clearTimeout(expiredTimer);

//   expiredTimer = setTimeout(() => {
//     alert('Token expired');

//     localStorage.removeItem('accessToken');

//     window.location.href = paths.auth.login;
//   }, timeLeft);
// };

// ----------------------------------------------------------------------

export const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);

    axiosFeaturesInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

    // This function below will handle when token is expired
    const { exp } = jwtDecode(accessToken); // ~3 days by minimals server
    tokenExpired(exp);
  } else {
    localStorage.removeItem('accessToken');

    delete axiosFeaturesInstance.defaults.headers.common.Authorization;
  }
};

export const generateFingerprint = async () => {
  let fingerprint = localStorage.getItem('fingerprint');
  if (!fingerprint) {
    /* eslint-disable no-restricted-globals */
    const canvas = document.createElement('canvas');
    const hardwareInfo = `${navigator.userAgent}${navigator.language}${screen.width}${screen.height}${screen.pixelDepth}${navigator.hardwareConcurrency}`;
    fingerprint = `${hardwareInfo}${canvas.toDataURL()}${new Date().getTime()}`;
    localStorage.setItem('fingerprint', fingerprint);
    /* eslint-enable no-restricted-globals */
  }

  const encoder = new TextEncoder();
  const data = encoder.encode(fingerprint);
  const buffer = await crypto.subtle.digest('SHA-256', data);
  const hashedFingerprint = Array.from(new Uint8Array(buffer))
    .map((byte) => byte.toString(16).padStart(2, '0'))
    .join('');

  return hashedFingerprint;
};
