import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/commons/contexts/auth/guard';
// components
import { LoadingScreen } from 'src/commons/components/loading-screen';
import HomeLayout from 'src/features/home/layout';
// import OverviewEcommercePage from 'src/pages/dashboard/ecommerce';

// ----------------------------------------------------------------------

// OVERVIEW
const IndexPage = lazy(() => import('src/features/home'));
// ----------------------------------------------------------------------

export const homeRoutes = [
  {
    path: '/',
    element: (
      <AuthGuard>
        <HomeLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </HomeLayout>
      </AuthGuard>
    ),
    children: [{ element: <IndexPage />, index: true }],
  },
];
