// assets
import { walletActiva, walletCredito, walletPesos, walletTurista } from 'src/assets/wallets';

export const getWalletProperties = (theme, walletType, isHome = false) => {
  const commonProperties = {
    backgroundColor: theme.palette.common.white,
    icon: null,
  };

  switch (
    walletType
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
  ) {
    case 'pesos':
      return {
        backgroundColor: !isHome ? theme.palette.wallets.green : theme.palette.wallets.pesos,
        icon: walletPesos,
        letterIcon: 'P',
      };
    case 'credito activa':
      return {
        backgroundColor: !isHome ? theme.palette.wallets.violet : theme.palette.wallets.activa,
        icon: walletActiva,
        letterIcon: 'A',
      };
    case 'credito turismo':
      return {
        backgroundColor: !isHome ? theme.palette.wallets.brown : theme.palette.wallets.turista,
        icon: walletTurista,
        letterIcon: 'T',
      };
    case 'credito fiscal':
      return {
        backgroundColor: !isHome ? theme.palette.wallets.yellow : theme.palette.wallets.credito,
        icon: walletCredito,
        letterIcon: 'F',
      };
    default:
      return commonProperties;
  }
};

export const handleCopyToClipboard = (text, type, snackbar) => {
  navigator.clipboard.writeText(text);
  snackbar(`${type} copiado con éxito!`);
};