// utils
import axiosFeaturesInstance, { endpoints } from './axios';

const getRecentAccounts = async () => {
  try {
    const response = await axiosFeaturesInstance.get(endpoints.sendMoney.recentAccounts, {});
    return response;
  } catch (error) {
    console.error('Error getting recent accounts:', error);
    throw error;
  }
};

const getAllRecentExternalAccounts = async () => {
  try {
    const response = await axiosFeaturesInstance.get(
      endpoints.sendMoney.allRecentExternalAccounts,
      {}
    );
    return response;
  } catch (error) {
    console.error('Error getting recent external accounts:', error);
    throw error;
  }
};

const getWallets = async () => {
  try {
    const response = await axiosFeaturesInstance.get(endpoints.sendMoney.wallets, {});
    return response;
  } catch (error) {
    console.error('Error getting wallets:', error);
    throw error;
  }
};

const getPaltaAccount = async (payload) => {
  try {
    const response = await axiosFeaturesInstance(endpoints.sendMoney.paltaAccount, {
      params: { cuit: payload },
    });
    return response;
  } catch (error) {
    console.error('Error getting palta account info:', error);
    throw error;
  }
};

const getExternalAccount = async (payload) => {
  try {
    const response = await axiosFeaturesInstance(endpoints.sendMoney.externalAccount, {
      params: { cbuOrAlias: payload },
    });
    return response;
  } catch (error) {
    console.error('Error getting external account:', error);
    throw error;
  }
};

const getSearchPaltaAccount = async (payload) => {
  try {
    const response = await axiosFeaturesInstance(endpoints.sendMoney.search, {
      params: { search: payload },
    });
    return response;
  } catch (error) {
    console.error('Error getting palta account:', error);
    throw error;
  }
};

const transfer = async (data) => {
  let response = null;
  const {
    amount,
    description,
    isNotPaltaAccount,
    receiverAlias,
    receiverCbu,
    receiverWalletId,
    senderWalletId,
  } = data;

  try {
    if (isNotPaltaAccount) {
      response = await axiosFeaturesInstance.post(endpoints.sendMoney.externalAccount, {
        amount,
        description,
        receiverAlias,
        receiverCbu,
        walletId: senderWalletId,
      });
    } else {
      response = await axiosFeaturesInstance.post(endpoints.sendMoney.paltaAccount, {
        amount,
        receiverWalletId,
        senderWalletId,
      });
    }
    return response;
  } catch (error) {
    console.error('Error when trying to transfer:', error);
    throw error;
  }
};

export const sendMoney = {
  getAllRecentExternalAccounts,
  getExternalAccount,
  getPaltaAccount,
  getRecentAccounts,
  getSearchPaltaAccount,
  getWallets,
  transfer,
};
