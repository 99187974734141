import PropTypes from 'prop-types';
// @mui
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';

const HeaderSubtitleSkeleton = ({ alignCenter = false }) => (
    <Box sx={{ textAlign: alignCenter ? 'center' : 'start' }}>
      <Skeleton variant="text" width={300} height={40} />
      <Skeleton
        variant="text"
        width={200}
        height={30}
        sx={{ marginTop: 0.5, mx: 'auto' }}
      />
    </Box>
  );

HeaderSubtitleSkeleton.propTypes = {
  alignCenter: PropTypes.bool,
};

export default HeaderSubtitleSkeleton;
