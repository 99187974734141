import PropTypes from 'prop-types';
// mui
import { useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
// utils
import { getWalletProperties } from 'src/commons/utils/helpers';

// ----------------------------------------------------------------------

const StoreWallet = ({ wallet }) => {
  const theme = useTheme();
  const {
    currency: { alternativeName },
    name,
  } = wallet;
  const { backgroundColor, icon: walletIcon } = getWalletProperties(theme, alternativeName);

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="center"
      gap={2}
      sx={{
        backgroundColor,
        borderRadius: '12px',
        height: '80px',
        width: '74vw',
        mr: 1.5,
        p: 3,
      }}
    >
      <Stack flexGrow={1} direction="column" alignItems="flex-start">
        <Typography
          variant="body1"
          fontSize={16}
          fontWeight="fontWeightSemiBold"
          sx={{ textTransform: 'capitalize', color: 'common.black' }}
        >
          {alternativeName}
        </Typography>
        <Typography
          variant="caption"
          fontSize={14}
          fontWeight="fontWeightMedium"
          sx={{ textTransform: 'capitalize', color: 'grey.700' }}
        >
          {name}
        </Typography>
      </Stack>
      <Box
        alignItems="center"
        justifyContent="center"
        sx={{
          borderRadius: 1.5,
          display: 'flex',
          backgroundColor: 'common.white',
          width: 48,
          height: 48,
        }}
      >
        <Box
          component="img"
          alignItems="center"
          src={walletIcon}
          justifyContent="center"
          sx={{
            width: 24,
            height: 24,
          }}
        />
      </Box>
    </Stack>
  );
};

StoreWallet.propTypes = {
  wallet: PropTypes.object,
};

export default StoreWallet;
