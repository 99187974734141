import PropTypes from 'prop-types';
// @mui
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';

const HeaderSubtitle = ({ title, subtitle, alignCenter = false, color, subtitleColor }) => {
  const theme = useTheme();
  return (
    <Box sx={{ textAlign: alignCenter ? 'center' : 'start' }}>
      <Typography sx={{ color: color || 'common.black' }} variant="h2" gutterBottom>
        {title}
      </Typography>
      {subtitle && (
        <Typography sx={{ color: subtitleColor || theme.palette.grey[600] }} variant="subtitle1" gutterBottom>
          {subtitle}
        </Typography>
      )}
    </Box>
  );
};

HeaderSubtitle.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  alignCenter: PropTypes.bool,
  color: PropTypes.string,
  subtitleColor: PropTypes.string
};
export default HeaderSubtitle;
