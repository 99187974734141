import PropTypes from 'prop-types';
import { useEffect, useContext } from 'react';
import { Helmet } from 'react-helmet-async';
// mui
import Box from '@mui/material/Box';
// components
import Header from 'src/commons/layouts/wrapper/header';
import StepContext from '../context/step-context';
import NewStoreInfo from '../pages/new-store-info';
import StoreInformation from './store-information';
import SelectWallets from './select-wallets';
import SelectAddress from '../pages/select-address';
import SuccessfullyStoreCreated from '../pages/successfully-store-created';

// ----------------------------------------------------------------------

export default function StoreNewEditForm({ currentStore, isEdit = false }) {
  const { activeStep, stepTitle, handleBack, handleNext } = useContext(StepContext);

  useEffect(() => {
    if (currentStore) handleNext('storeNewEditForm');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStore]);

  const stepsComponentsMap = {
    newStoreInfo: !isEdit ? <NewStoreInfo /> : null,
    storeNewEditForm: <StoreInformation currentStore={currentStore} />,
    selectWallets: <SelectWallets currentStore={currentStore} />,
    selectAddress: <SelectAddress currentStore={currentStore} />,
    successfullyStoreCreated: <SuccessfullyStoreCreated />,
  };

  const currentStepComponent = stepsComponentsMap[activeStep];

  return (
    <>
      <Helmet>
        <title>{currentStore ? 'Editar Local' : 'Crear Local'}</title>
      </Helmet>
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
        <Header title={stepTitle} onGoBack={handleBack} />
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            pt: 0,
          }}
        >
          {currentStepComponent}
        </Box>
      </Box>
    </>
  );
}

StoreNewEditForm.propTypes = {
  currentStore: PropTypes.object,
  isEdit: PropTypes.bool,
};
