import PropTypes from 'prop-types';
import Grid from '@mui/material/Unstable_Grid2';
import { Button } from '@mui/material';
import { m } from 'framer-motion';
import { useTheme } from '@mui/material/styles';
import Iconify from '../iconify/iconify';

const NumericPad = ({ onNumberClick, onDeleteClick, disabled, showDot }) => {
  const theme = useTheme();
  const numericButtons = Array.from({ length: 9 }, (_, index) => index + 1);

  const handleNumberClick = (number) => {
    if (!disabled) {
      onNumberClick(number);
    }
  };

  const handleDeleteClick = () => {
    onDeleteClick();
  };

  const buttonAnimation = {
    scale: [1, 1.1, 1],
    transition: {
      duration: 0.2,
    },
  };

  return (
    <Grid container spacing={1}>
      {numericButtons.map((number) => (
        <Grid key={number} xs={4}>
          <m.div whileTap={buttonAnimation}>
            {/* Wrap the Button with m.div */}
            <Button
              variant="numeric"
              fullWidth
              sx={{ color: theme.palette.green.dark }}
              onClick={() => handleNumberClick(number)}
              disabled={disabled}
            >
              {number}
            </Button>
          </m.div>
        </Grid>
      ))}
      <Grid xs={4}>
        {showDot ? (
          <m.div whileTap={buttonAnimation}>
            {/* Wrap the Button with m.div */}
            <Button
              variant="numeric"
              fullWidth
              sx={{ color: theme.palette.green.dark }}
              onClick={() => handleNumberClick('.')} // Use dot '.' for decimal
              disabled={disabled}
            >
              .
            </Button>
          </m.div>
        ) : (
          <Button variant="numeric" disabled fullWidth>
            {/* <Iconify
              icon="ph:fingerprint-light"
              width={32}
              sx={{ color: theme.palette.green.dark }}
            /> */}
          </Button>
        )}
      </Grid>

      <Grid xs={4}>
        <m.div whileTap={buttonAnimation}>
          {/* Wrap the Button with m.div */}
          <Button
            variant="numeric"
            fullWidth
            onClick={() => handleNumberClick(0)}
            disabled={disabled}
          >
            0
          </Button>
        </m.div>
      </Grid>
      <Grid xs={4}>
        <m.div whileTap={buttonAnimation}>
          {/* Wrap the Button with m.div */}
          <Button variant="numeric" fullWidth onClick={handleDeleteClick}>
            <Iconify icon="cil:delete" width={28} sx={{ color: theme.palette.green.dark }} />
          </Button>
        </m.div>
      </Grid>
    </Grid>
  );
};

NumericPad.propTypes = {
  onNumberClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  disabled: PropTypes.bool,
  showDot: PropTypes.bool,
};

export default NumericPad;
