import PropTypes from 'prop-types';
import Skeleton from '@mui/material/Skeleton';

function WalletSkeleton({ height = 220, sx }) {
  return (
    <Skeleton
      animation="wave"
      sx={{ my: 2, borderRadius: 1.5, width: 1, ...sx }}
      variant="rectangular"
      height={height}
    />
  );
}

WalletSkeleton.propTypes = {
  height: PropTypes.number,
  sx: PropTypes.object,
};
export default WalletSkeleton;
