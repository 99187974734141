// @mui
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
// ----------------------------------------------------------------------

export default function MovementSkeleton() {
    return (
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          justifyContent="space-between"
          sx={{ width: 1, borderBottom: 0.5, borderColor: 'grey.100', pb: 1.25, my: 1 }}
        >
          <Skeleton variant="circular" width={40} height={40} />
  
          <Stack direction="column" justifyContent="flex-start" alignItems="flex-start" flexGrow={1}>
            <Skeleton width={100} height={20} />
            <Skeleton width={80} height={15} />
          </Stack>
  
          <Stack direction="row" spacing={1} justifyContent="flex-end" alignItems="center">
            <Skeleton variant="circular" width={20} height={20} />
            <Skeleton width={60} height={20} />
          </Stack>
        </Stack>
      );
}
