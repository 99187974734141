import { useState, useEffect } from 'react';
import { m } from 'framer-motion';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';
// @mui
import { useTheme } from '@mui/material/styles';
import { Typography, Badge, IconButton, Stack, AppBar, Toolbar } from '@mui/material';
// hooks
import { useOffSetTop } from 'src/commons/hooks/use-off-set-top';
import { useResponsive } from 'src/commons/hooks/use-responsive';
import { useAuthContext } from 'src/commons/contexts/auth/hooks';
// components
import Avatar from 'src/commons/components/avatar';
// import Logo from 'src/components/logo';
import Iconify from 'src/commons/components/iconify';
import { useSettingsContext } from 'src/commons/components/settings';
import { varHover } from 'src/commons/components/animate';
import { services } from 'src/services/api';
// services
//
import { HEADER, NAV } from './config-layout';

// ----------------------------------------------------------------------

export default function Header({ onOpenNav }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const settings = useSettingsContext();
  const lgUp = useResponsive('up', 'lg');
  const offset = useOffSetTop(HEADER.H_DESKTOP);
  const { user } = useAuthContext();
  const [unreadNotifications, setUnreadNotifications] = useState(null);

  useEffect(() => {
    const fetch = async () => {
      try {
        const res = await services.features.notifications.getUnreadNotifications();
        if (res?.success) setUnreadNotifications(res?.data);
      } catch (err) {
        console.error();
      }
    };
    fetch();
  }, [unreadNotifications]);

  const isNavHorizontal = settings.themeLayout === 'horizontal';
  const isNavMini = settings.themeLayout === 'mini';
  const offsetTop = offset && !isNavHorizontal;

  const renderContent = (
    <>
      <Avatar
        alt={`${user?.name} ${user?.lastname}`}
        src={user?.avatar}
        onClick={() => navigate('/more')}
        sx={{
          width: 40,
          height: 40,
        }}
      />

      <Stack
        direction="column"
        alignItems="flex-start"
        sx={{ ml: 1, color: theme.palette.common.white }}
        justifyContent="center"
      >
        <Typography
          variant="subtitle1"
          fontWeight={900}
          fontSize={16}
          sx={{ textTransform: 'capitalize' }}
        >
          Hola, {user?.name}
        </Typography>
        <Typography variant="subtitle1" fontWeight="fontWeightMedium" fontSize={14}>
          Bienvenido a Palta
        </Typography>
      </Stack>

      <Stack
        flexGrow={1}
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={{ xs: 0.5, sm: 1 }}
      >
        <IconButton
          component={m.button}
          variants={varHover(1.05)}
          color="primary"
          onClick={() => navigate('/more/need-help')}
        >
          <Iconify icon="ci:circle-help" color="white" width={26} />
        </IconButton>
        <IconButton
          component={m.button}
          variants={varHover(1.05)}
          color="primary"
          onClick={() => navigate('/notifications')}
        >
          <Badge badgeContent={unreadNotifications} color="error">
            <Iconify icon="eva:bell-outline" color="white" width={26} />
          </Badge>
        </IconButton>
      </Stack>
    </>
  );

  return (
    <AppBar
      sx={{
        position: 'absolute',
        height: HEADER.H_MOBILE,
        zIndex: theme.zIndex.appBar + 1,
        transition: theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(lgUp && {
          width: `calc(100% - ${NAV.W_VERTICAL + 1}px)`,
          height: HEADER.H_DESKTOP,
          ...(offsetTop && {
            height: HEADER.H_DESKTOP_OFFSET,
          }),
          ...(isNavHorizontal && {
            width: 1,
            bgcolor: 'background.default',
            height: HEADER.H_DESKTOP_OFFSET,
            borderBottom: `dashed 1px ${theme.palette.divider}`,
          }),
          ...(isNavMini && {
            width: `calc(100% - ${NAV.W_MINI + 1}px)`,
          }),
        }),
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { lg: 5 },
        }}
      >
        {renderContent}
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {
  onOpenNav: PropTypes.func,
};
