import { useEffect, useContext } from 'react';
// mui
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
// components
import Image from 'src/commons/components/image';
import Iconify from 'src/commons/components/iconify';
// assets
import { newStore } from '../assets/illustrations';
import StepContext from '../context/step-context';

export default function NewStoreInfo() {
  const { handleNext, setStepTitle } = useContext(StepContext);
  useEffect(() => {
    setStepTitle('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Stack
      direction="column"
      justifyContent="flex-start"
      sx={{
        height: 1,
        px: 2,
        pb: 3,
      }}
    >
      <Stack
        direction="column"
        sx={{ color: 'common.white', textAlign: 'center', pt: 6 }}
        alignItems="center"
        justifyContent="center"
        spacing={4}
        flexGrow={1}
      >
        <Typography
          fontSize={28}
          fontWeight="fontWeightExtraBold"
          sx={{ color: 'common.black' }}
          variant="h2"
        >
          Potencia tu negocio con{' '}
          <Box component="span" sx={{ color: 'green.primary', fontSize: 28 }}>
            Locales
          </Box>
        </Typography>
        <Image
          alt="Crea un nuevo local"
          src={newStore}
          sx={{
            objectPosition: 'center',
          }}
        />
        <Typography sx={{ color: 'grey.700' }} fontWeight="fontWeightMedium" variant="body1">
          Crea y gestiona múltiples locales para expandir tu presencia y alcanzar más clientes+
        </Typography>
        <Button
          onClick={() => handleNext('storeNewEditForm')}
          startIcon={<Iconify icon="ic:round-plus" sx={{ color: 'green.primary' }} />}
          fullWidth
          size="large"
          color="primary"
          variant="outlined"
          sx={{ width: 1, mt: 4, color: 'green.dark', fontWeight: 500 }}
        >
          Nuevo local
        </Button>
      </Stack>
    </Stack>
  );
}
