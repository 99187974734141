import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
// import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Fab from '@mui/material/Fab';
import Typography from '@mui/material/Typography';
import Iconify from 'src/commons/components/iconify';
// Layout
import { HEADER } from '../config-layout';

// ----------------------------------------------------------------------

export default function Header({ title, actions, onGoBack, path, hideGoBack = false, sx }) {
  const theme = useTheme();
  const navigate = useNavigate();

  const handleGoBack = () => {
    if (onGoBack) {
      onGoBack();
    } else {
      navigate(path || -1);
    }
  };

  return (
    <AppBar position="static">
      <Toolbar
        disableGutters
        sx={{
          height: HEADER.H_MOBILE,
          ...sx,
        }}
      >
        <Container
          sx={{ height: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
        >
          <Fab
            size="small"
            variant="soft"
            sx={{
              width: 32,
              height: 32,
              minHeight: 32,
              backgroundColor: theme.palette.grey[100],
              display: hideGoBack ? 'none' : 'inherit',
            }}
            color={theme.palette.grey[100]}
            aria-label="go-back"
            onClick={handleGoBack}
          >
            <Iconify sx={{ color: theme.palette.green.dark }} icon="ion:chevron-back" width={22} />
          </Fab>
          {title && (
            <Typography
              variant="h3"
              fontSize={18}
              fontWeight="fontWeightBold"
              sx={{ ml: actions ? 0 : -2, textAlign: 'center' }}
            >
              {title}
            </Typography>
          )}

          <Stack alignItems="center" direction={{ xs: 'row', md: 'row-reverse' }}>
            {actions}
          </Stack>
        </Container>
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {
  title: PropTypes.string,
  actions: PropTypes.node,
  onGoBack: PropTypes.func,
  hideGoBack: PropTypes.bool,
  path: PropTypes.string,
  sx: PropTypes.object,
};
