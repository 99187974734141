import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
// mui
import { useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
// routes
import { useParams } from 'src/routes/hook';
import { paths } from 'src/routes/paths';
// services
import { services } from 'src/services/api';
// components
import Iconify from 'src/commons/components/iconify';
import { RouterLink } from 'src/routes/components';
// layouts
import Header from 'src/commons/layouts/wrapper/header';
// sections
import WalletDetailsView from '../components/wallet-details-view';
// helpers
import { getWalletProperties } from '../utils/helpers';

// ----------------------------------------------------------------------

export default function WalletDetailsPage() {
  const theme = useTheme();

  const params = useParams();
  const { id } = params;
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [loadingWallet, setLoadingWallet] = useState(true);
  const [loadingMovements, setLoadingMovements] = useState(true);
  const [wallet, setWallet] = useState(null);
  const [movements, setMovements] = useState([]);
  const [icon, setIcon] = useState(null);
  const [walletBackgroundColor, setWalletBackgroundColor] = useState('grey.100');
  const handleOpenDrawer = () => {
    setIsDrawerOpen(true);
  };

  const handleCloseDrawer = () => {
    setIsDrawerOpen(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [walletRes, movementsRes] = await Promise.all([
          services.features.wallets.getById(id),
          services.features.wallets.listMovements(id),
        ]);

        if (walletRes.success) {
          setWallet(walletRes.data);
          const { backgroundColor, icon: walletIcon } = getWalletProperties(
            theme,
            walletRes.data.currency?.alternativeName
          );
          setWalletBackgroundColor(backgroundColor);
          setIcon(walletIcon);
        }

        if (movementsRes.success) {
          setMovements(movementsRes.data);
        }

        setLoadingWallet(false);
        setLoadingMovements(false);
      } catch (err) {
        console.error('Error fetching data', err);
        setLoadingWallet(false);
        setLoadingMovements(false);
      }
    };

    fetchData();
  }, [id, theme]);

  const openDrawerIcon = (
    <IconButton
      onClick={() => setIsDrawerOpen(true)}
      sx={{
        p: 0.25,
        borderRadius: 1.5,
        width: 32,
        height: 32,
      }}
    >
      <Iconify width={32} sx={{ color: 'common.black' }} icon="material-symbols:more-vert" />
    </IconButton>
  );

  return (
    <>
      <Helmet>
        <title> Detalles Billetera </title>
      </Helmet>
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
        <Header actions={openDrawerIcon} />
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            pt: 0,
            px: 2,
            pb: 2,
          }}
        >
          <WalletDetailsView
            wallet={wallet}
            movements={movements}
            loadingWallet={loadingWallet}
            loadingMovements={loadingMovements}
            icon={icon}
            walletBackgroundColor={walletBackgroundColor}
          />
          <SwipeableDrawer
            anchor="bottom"
            open={isDrawerOpen}
            onClose={handleCloseDrawer}
            onOpen={handleOpenDrawer}
          >
            <Stack direction="column" spacing={2} sx={{ px: 2, py: 6 }}>
              <Button
                size="large"
                component={RouterLink}
                href={paths.home.wallets.edit(id)}
                variant="outlined"
                startIcon={<Iconify icon="ri:edit-line" />}
                sx={{
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  fontSize: '14px',
                  fontWeight: 'fontWeightRegular',
                }}
                endIcon={
                  <Iconify
                    sx={{
                      position: 'absolute',
                      mr: 2,
                      right: 0,
                      bottom: '14px',
                      color: 'green.primary',
                      width: 26,
                      height: 26,
                    }}
                    icon="ic:round-chevron-right"
                  />
                }
              >
                Editar
              </Button>
              <Button
                size="large"
                component={RouterLink}
                href={paths.home.wallets.hide(id)}
                variant="outlined"
                startIcon={<Iconify icon={`${wallet?.isHidden ? 'bx:show' : 'iconamoon:trash'}`} />}
                sx={{
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  fontSize: '14px',
                  fontWeight: 'fontWeightRegular',
                }}
                endIcon={
                  <Iconify
                    sx={{
                      position: 'absolute',
                      mr: 2,
                      right: 0,
                      bottom: '14px',
                      color: 'green.primary',
                      width: 26,
                      height: 26,
                    }}
                    icon="ic:round-chevron-right"
                  />
                }
              >
                {wallet?.isHidden ? 'Mostrar' : 'Ocultar'}
              </Button>
            </Stack>
          </SwipeableDrawer>
        </Box>
      </Box>
    </>
  );
}
