import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Stack, Box, Divider } from '@mui/material';
// assets
import ActionableItem from 'src/features/more/components/actionable-item';
import Header from 'src/commons/layouts/wrapper/header';

// ----------------------------------------------------------------------

export default function Configurations() {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title> Configuración </title>
      </Helmet>
      <Header title="Configuración" />
      <Stack component="main" direction="column" justifyContent="space-between">
        <Stack px={2} mt={5}>
          <Stack spacing={2.5}>
            <ActionableItem
              title="Datos personales"
              icon="fluent:data-usage-edit-24-regular"
              arrowIcon
              onClick={() => navigate('/more/configurations/personal-data')}
            />
            <Divider light />
            <ActionableItem
              title="Cambia tu Numero de Telefono"
              icon="fluent:data-usage-edit-24-regular"
              arrowIcon
              onClick={() => navigate('/more/configurations/change-phone')}
            />
            {/* <Divider light />
            <ActionableItem
              title="Seguridad"
              icon="mdi:account-security-outline"
              arrowIcon
              onClick={() => navigate('/more/configurations/security')}
            /> */}
            <Divider light />
            <ActionableItem
              title="Eliminar cuenta"
              icon="iconamoon:trash"
              color={theme.palette.error.main}
              bgcolor="#FFEAEA"
              arrowIcon
              onClick={() => navigate('/more/configurations/delete-account')}
            />
          </Stack>
        </Stack>
        <Box
          sx={{
            flexGrow: 1,
            p: 2,
          }}
        />
      </Stack>
    </>
  );
}
