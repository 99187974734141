// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: '/auth',
  AUTH_DEMO: '/auth-demo',
  HOME: '/',
};

// ----------------------------------------------------------------------

export const paths = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
  docs: 'https://docs.minimals.cc',
  changelog: 'https://docs.minimals.cc/changelog',
  zoneUI: 'https://mui.com/store/items/zone-landing-page/',
  minimalUI: 'https://mui.com/store/items/minimal-dashboard/',
  freeUI: 'https://mui.com/store/items/minimal-dashboard-free/',
  figma:
    'https://www.figma.com/file/kAYnYYdib0aQPNKZpgJT6J/%5BPreview%5D-Minimal-Web.v5.0.0?type=design&node-id=0%3A1&t=Al4jScQq97Aly0Mn-1',
  // AUTH
  auth: {
    welcome: `${ROOTS.AUTH}/welcome`,
    login: `${ROOTS.AUTH}/login`,
    register: `${ROOTS.AUTH}/register`,
    unlock: `${ROOTS.AUTH}/unlock`,
    forgetPin: `${ROOTS.AUTH}/forget-pin`,
    userAlreadyExists: `${ROOTS.AUTH}/user-already-exists`,
  },
  // HOME
  home: {
    root: ROOTS.HOME,
    unlock: '/unlock',
    history: `/history`,
    wallets: {
      root: `/wallets`,
      new: `/wallets/create`,
      successfullyCreated: '/wallets/successfully-created',
      details: (id) => `/wallets/${id}`,
      edit: (id) => `/wallets/${id}/edit`,
      hide: (id) => `/wallets/${id}/hide`,
    },
    operations: {
      root: `/operations`,
      details: (id) => `/operations/${id}`,
    },
    updateAlias: `/update-alias`,
    chargeYourPalta: `/charge-your-palta`,
    storesAndPromos: {
      root: `/stores-and-promos`,
      details: (id) => `/stores-and-promos/${id}`,
    },
    qrPayment: `/qr-payment`,
    sendMoney: `/send-money`,
    // more
    more: `/more`,
    needHelp: `/more/need-help`,
    configurations: `/more/configurations`,
    tyc: `/more/legals/terms-and-conditions`,
    // stores
    stores: {
      root: `/my-stores`,
      details: (id) => `/my-stores/${id}`,
      edit: (id) => `/my-stores/${id}/edit`,
      new: `/my-stores/create`
    },
  },
};
