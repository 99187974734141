import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
// mui
import Box from '@mui/material/Box';
// routes
import { useParams } from 'src/routes/hook';
// services
import { services } from 'src/services/api';
// components
import { LoadingScreen } from 'src/commons/components/loading-screen';
// layouts
// sections
import OperationDetailsView from '../components/operations-details-view';

// ----------------------------------------------------------------------

export default function OperationDetailsPage() {
  const params = useParams();
  const { id } = params;
  const [isLoading, setIsLoading] = useState(true);
  const [operation, setOperation] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await services.features.operations.get(id);
        if (res.success) {
          setOperation(res?.data);
        }
        setIsLoading(false);
      } catch (err) {
        console.error('Error fetching data', err);
        setIsLoading(false);
      }
    };

    fetchData();
  }, [id]);

  return (
    <>
      <Helmet>
        <title> Detalles de Operación </title>
      </Helmet>
      <Box sx={{ display: 'flex', flexDirection: 'column', height: 1 }}>
        <Box
          sx={{
            flexGrow: 1,
          }}
        >
          {isLoading ? (
            <LoadingScreen
              sx={{
                borderRadius: 3,
                color: 'green.primary',
              }}
            />
          ) : (
            <OperationDetailsView operation={operation} isLoading={isLoading} />
          )}
        </Box>
      </Box>
    </>
  );
}
