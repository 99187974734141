import { useEffect } from 'react';
import * as wvg from 'webviewgold-sdk';
import { Helmet } from 'react-helmet-async';
// @mui
import { useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { RouterLink } from 'src/routes/components';
// routes
import { paths } from 'src/routes/paths';
// components
import Image from 'src/commons/components/image';
import HeaderSubtitle from 'src/commons/components/header-subtitle/header-subtitle';
// assets
import { storeCreated } from '../assets/illustrations';

// ----------------------------------------------------------------------

export default function SuccessfullyStoreCreated() {
  const theme = useTheme();

  useEffect(() => {
    if (!(window.location.hostname === 'localhost')) {
      wvg.setStatusBarColor('14,22,5');
    }

    return () => {
      if (!(window.location.hostname === 'localhost')) {
        wvg.setStatusBarColor('255,255,255');
      }
    };
  }, []);

  return (
    <>
      <Helmet>
        <title> Billetera creada con éxito </title>
      </Helmet>
      <Stack
        component="main"
        direction="column"
        justifyContent="space-between"
        sx={{
          position: 'absolute',
          inset: 0,
          height: '100vh',
          p: 2,
          background: theme.palette.green.backgroundFill,
        }}
      >
        <Stack
          direction="column"
          sx={{ color: 'common.white' }}
          alignItems="center"
          justifyContent="center"
          flexGrow={1}
        >
          <Image
            alt="Local creado con éxito"
            src={storeCreated}
            sx={{
              objectPosition: 'center',
            }}
          />
          <HeaderSubtitle
            title="¡Felicitaciones, Local creada con éxito!"
            color="common.white"
            alignCenter
          />
        </Stack>

        <Stack
          direction="column"
          alignItems="center"
          spacing={2}
          justifyContent="center"
          sx={{ px: 1, color: 'common.white' }}
        >
          <Button
            component={RouterLink}
            href={paths.home.stores.root}
            fullWidth
            size="large"
            color="primary"
            variant="contained"
            sx={{ width: 1 }}
          >
            Explorar local
          </Button>
          <Button
            component={RouterLink}
            href={paths.home.root}
            fullWidth
            size="large"
            variant="outlined"
            sx={{ width: 1 }}
          >
            Ir al inicio
          </Button>
        </Stack>
      </Stack>
    </>
  );
}

// ----------------------------------------------------------------------
