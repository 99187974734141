// utils
import axiosFeaturesInstance, { endpoints } from './axios';

const list = async (ownerId) => {
  try {
    const response = await axiosFeaturesInstance(endpoints.myStores.root, { params: { ownerId } });
    return response;
  } catch (error) {
    console.error('Error during list stores:', error);
    throw error;
  }
};

const create = async (data) => {
  try {
    const response = await axiosFeaturesInstance.post(endpoints.myStores.root, data);
    return response;
  } catch (error) {
    console.error('Error creating store:', error);
    throw error;
  }
};

const getById = async (storeId) => {
  try {
    const response = await axiosFeaturesInstance(`${endpoints.myStores.root}/${storeId}`);
    return response;
  } catch (error) {
    console.error('Error during store list:', error);
    throw error;
  }
};

const update = async (data, storeId) => {
  try {
    const response = await axiosFeaturesInstance.patch(
      `${endpoints.myStores.root}/${storeId}`,
      data
    );
    return response;
  } catch (error) {
    console.error('Error creating store:', error);
    throw error;
  }
};

export const myStores = { list, create, getById, update };
