import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useState, useContext, useMemo, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
// components
import { useSnackbar } from 'src/commons/components/snackbar';
import FormProvider, { RHFAutocomplete, RHFTextField } from 'src/commons/components/hook-form';
// services
import { services } from 'src/services/api';
// context
import StepContext from '../context/step-context';
import { useFormContext } from '../context/form-context';

// ----------------------------------------------------------------------

export default function StoreInformation({ currentStore }) {
  const { setStepTitle, setPreviousStep, handleNext } = useContext(StepContext);
  const formData = useFormContext();

  const { enqueueSnackbar } = useSnackbar();

  const [fields, setFields] = useState([]);

  const NewStoreSchema = Yup.object().shape({
    name: Yup.string()
      .min(4, 'El nombre debe tener al menos 4 caracteres')
      .max(100, 'El nombre no puede exceder los 100 caracteres')
      .required('El nombre es obligatorio'),
    fields: Yup.array(),
  });

  const defaultValues = useMemo(
    () => ({
      name: currentStore?.name || '',
      fields: currentStore?.fields || [],
    }),
    [currentStore]
  );

  const methods = useForm({
    mode: 'onChange',
    delayError: 500,
    resolver: yupResolver(NewStoreSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting, isValid },
  } = methods;

  useEffect(() => {
    if (currentStore) {
      reset(defaultValues);
    }
  }, [currentStore, defaultValues, reset]);

  useEffect(() => {
    setStepTitle(currentStore ? 'Editar local' : 'Nuevo local');
    setPreviousStep(currentStore ? 'goBack' : 'newStoreInfo');

    const fetchFieldOptions = async () => {
      try {
        const res = await services.features.configs.listFields();
        if (res.success) setFields(res?.data?.fields);
      } catch (err) {
        enqueueSnackbar(err.message, { variant: 'error' });
      }
    };
    fetchFieldOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = handleSubmit((data) => {
    // eslint-disable-next-line no-shadow
    const { name, fields } = data;
    formData.onUpdate('name', name);
    formData.onUpdate('fields', fields);
    handleNext('selectWallets');
  });

  return (
    <Stack sx={{ height: 1, width: 1, px: 2, pb: 3, pt: 2 }}>
      <FormProvider fullHeight methods={methods} onSubmit={onSubmit}>
        <Stack direction="column" alignItems="center" flexGrow={1} spacing={2} sx={{ width: 1 }}>
          <RHFTextField required name="name" label="Nombre" fullWidth />
          <RHFAutocomplete
            multiple
            name="fields"
            options={fields}
            fullWidth
            disableCloseOnSelect
            getOptionLabel={(option) => option}
            isOptionEqualToValue={(option, value) => option === value}
            renderInput={(params) => <TextField placeholder="Rubro" {...params} />}
            renderOption={(props, option) => (
              <li {...props} key={option} style={{ textTransform: 'capitalize' }}>
                {option}
              </li>
            )}
            renderTags={(selected, getTagProps) =>
              selected.map((option, index) => (
                <Chip
                  {...getTagProps({ index })}
                  key={option}
                  label={option}
                  size="small"
                  color="primary"
                  variant="soft"
                />
              ))
            }
          />
        </Stack>
        <Box sx={{ position: 'absolute', bottom: 0, pb: 3, px: 2, left: 0, width: 1 }}>
          <LoadingButton
            fullWidth
            disabled={!isValid}
            type="submit"
            color="primary"
            variant="contained"
            size="large"
            loading={isSubmitting}
          >
            Continuar
          </LoadingButton>
        </Box>
      </FormProvider>
    </Stack>
  );
}

StoreInformation.propTypes = {
  currentStore: PropTypes.object,
};
