import PropTypes from 'prop-types';
import React from 'react';
// @mui
import MUIAvatar from '@mui/material/Avatar';
// utils
import * as utils from 'src/commons/utils';

// ----------------------------------------------------------------------

export default function Avatar({ title = '', alt = '', img = '', children, sx, ...props }) {
  const NAME = title || alt;
  const INITIALS = utils.extractInitials(NAME);
  const STRING_COLOR = utils.stringToColor(NAME);
  const WHITE_OR_BLACK = utils.getContrastColor(STRING_COLOR);

  return (
    <MUIAvatar
      sx={{
        backgroundColor: STRING_COLOR,
        color: WHITE_OR_BLACK,
        width: '46px',
        height: '46px',
        textTransform: 'capitalize',
        ...sx,
      }}
      alt={NAME}
      src={img}
      {...props}
    >
      {children || INITIALS}
    </MUIAvatar>
  );
}

Avatar.propTypes = {
  title: PropTypes.string,
  alt: PropTypes.string,
  img: PropTypes.string,
  children: PropTypes.any,
  sx: PropTypes.object,
};
