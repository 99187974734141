import PropTypes from 'prop-types';
// @mui
import Box from '@mui/material/Box';
// components
import AppBar from 'src/features/home/components/app-bar/app-bar';
//
import Main from './main';

// ----------------------------------------------------------------------

export default function HistoryLayout({ children }) {
  return (
    <>
      <Box
        sx={{
          minHeight: 1,
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
        }}
      >
        <Main>{children}</Main>
      </Box>
      <AppBar />
    </>
  );
}

HistoryLayout.propTypes = {
  children: PropTypes.node,
};
