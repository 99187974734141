import PropTypes from 'prop-types';
import { forwardRef } from 'react';
// @mui
// import { useTheme } from '@mui/material/styles';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
// routes
import { RouterLink } from 'src/routes/components';

// ----------------------------------------------------------------------

const Logo = forwardRef(({ greenLogo = false, disabledLink = false, sx, ...other }, ref) => {
  const srcLogo = greenLogo ? '/logo/palta.svg' : '/logo/logotipo-white.svg';
  // -------------------------------------------------------
  const logo = <Box component="img" src={srcLogo} sx={{ height: 48, ...sx }} />;

  if (disabledLink) {
    return logo;
  }

  return (
    <Link component={RouterLink} href="/" sx={{ display: 'contents' }}>
      {logo}
    </Link>
  );
});

Logo.propTypes = {
  greenLogo: PropTypes.bool,
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default Logo;
