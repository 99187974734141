import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
// mui
import { useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
// routes
import { paths } from 'src/routes/paths';
import { useParams } from 'src/routes/hook';
import { RouterLink } from 'src/routes/components';
// services
import { services } from 'src/services/api';
// components
import Iconify from 'src/commons/components/iconify';
// layouts
import { getWalletProperties } from 'src/features/wallets/utils/helpers';
import { useNavigate } from 'react-router';
import StoreMap from '../components/stores/store-map';

// ----------------------------------------------------------------------

export default function StoreDetailsPage() {
  const theme = useTheme();
  const navigate = useNavigate();
  const params = useParams();
  const { id } = params;
  const [isLoading, setIsLoading] = useState(true);
  const [locationIsLoading, setLocationIsLoading] = useState(true);
  const [store, setStore] = useState(null);
  const [storeLocation, setStoreLocation] = useState(null);

  useEffect(() => {
    const fetchStore = async () => {
      try {
        setIsLoading(true);
        const res = await services.features.storesAndPromos.getById(id);
        if (res?.success) {
          setStore(res?.data);
          const address = res?.data?.address;
          if (address) {
            try {
              const locationData = await services.googleMaps.getLatLngFromAddress(address);
              if (locationData) {
                setStoreLocation(locationData);
                setLocationIsLoading(false);
              }
            } catch (error) {
              console.error('Error while fetching latitude and longitude', error);
            }
          }
        }
      } catch (err) {
        console.error('Error while fetching store data', err);
      } finally {
        setIsLoading(false);
      }
    };
    fetchStore();
  }, [id]);

  return (
    <>
      <Helmet>
        <title> Detalles Tienda </title>
      </Helmet>
      <Stack component="main" direction="column" sx={{ width: 1 }}>
        <Box
          component="div"
          sx={{
            width: 1,
            height: '55vh',
            position: 'relative',
          }}
        >
          <Fab
            size="small"
            variant="soft"
            component={RouterLink}
            onClick={() => navigate(-1)}
            sx={{
              width: 32,
              height: 32,
              minHeight: 32,
              m: 2,
              position: 'absolute',
              boxShadow: `2px 2px 35px 20px ${theme.palette.common.white}`,
            }}
            color={theme.palette.grey[100]}
            aria-label="go-back"
          >
            <Iconify sx={{ color: theme.palette.green.dark }} icon="ion:chevron-back" width={22} />
          </Fab>
          <StoreMap
            latitude={storeLocation?.latitude}
            longitude={storeLocation?.longitude}
            name={store?.name}
            isLoading={locationIsLoading && !storeLocation}
          />
        </Box>

        <Stack direction="column" spacing={3} sx={{ width: 1, p: 2 }}>
          <Stack direction="row" alignItems="center">
            <Stack direction="column" flexGrow={1}>
              <Typography
                fontWeight="fontWeightBold"
                fontSize={28}
                variant="h1"
                color="green.dark"
                sx={{ textTransform: 'capitalize' }}
              >
                {isLoading ? <Skeleton animation="wave" width={200} /> : store?.name}
              </Typography>
              <Typography
                fontWeight="fontWeightMedium"
                variant="caption"
                color="grey.700"
                sx={{ textTransform: 'capitalize' }}
              >
                {isLoading ? (
                  <Skeleton animation="wave" width={150} />
                ) : (
                  `Estamos en ${store?.address}`
                )}
              </Typography>
            </Stack>
          </Stack>
          <Stack direction="column" spacing={1} sx={{ width: 1 }}>
            <Typography
              fontWeight="fontWeightSemiBold"
              variant="subtitle1"
              fontSize={13}
              color="common.dark"
              sx={{ textTransform: 'capitalize' }}
            >
              Acepta
            </Typography>
            <Stack direction="row" alignItems="center" spacing={1} sx={{ width: 1 }}>
              {store?.wallets?.length === 0 && (
                <Typography
                  fontWeight="fontWeightMedium"
                  variant="caption"
                  color="common.dark"
                  sx={{ textTransform: 'capitalize' }}
                >
                  Este comercio no cuenta con billeteras asociadas
                </Typography>
              )}
              {store?.wallets?.length > 0 &&
                store?.wallets?.map((wallet) => {
                  const { _id, currency } = wallet;
                  const { backgroundColor } = getWalletProperties(theme, currency?.alternativeName);

                  return (
                    <Box
                      key={_id}
                      component="div"
                      alignItems="center"
                      justifyContent="center"
                      sx={{
                        borderRadius: 1,
                        display: 'flex',
                        backgroundColor,
                        height: 37,
                        px: 1,
                      }}
                    >
                      <Typography
                        fontWeight="fontWeightMedium"
                        variant="caption"
                        color="common.dark"
                        sx={{ textTransform: 'capitalize' }}
                      >
                        {currency?.alternativeName}
                      </Typography>
                    </Box>
                  );
                })}
            </Stack>
          </Stack>
          <Stack direction="column" spacing={1} sx={{ width: 1 }}>
            <Stack direction="column" spacing={1} sx={{ width: 1 }}>
              <Typography
                fontWeight="fontWeightSemiBold"
                variant="subtitle1"
                fontSize={13}
                color="common.dark"
                sx={{ textTransform: 'capitalize' }}
              >
                Rubro
              </Typography>
              <Typography
                fontWeight="fontWeightMedium"
                variant="caption"
                color="grey.700"
                sx={{ textTransform: 'capitalize' }}
              >
                {isLoading ? (
                  <Skeleton animation="wave" width={150} />
                ) : (
                  store?.fields?.join(',') || '-'
                )}
              </Typography>
              <Divider />
            </Stack>
            <Stack direction="column" spacing={1} sx={{ width: 1 }}>
              <Typography
                fontWeight="fontWeightSemiBold"
                variant="subtitle1"
                fontSize={13}
                color="common.dark"
                sx={{ textTransform: 'capitalize' }}
              >
                Descripción
              </Typography>
              <Typography
                fontWeight="fontWeightMedium"
                variant="caption"
                color="grey.700"
                sx={{ textTransform: 'capitalize' }}
              >
                {isLoading ? <Skeleton animation="wave" width={150} /> : store?.description || '-'}
              </Typography>
            </Stack>
          </Stack>
          <Stack direction="row" spacing={1} sx={{ width: 1 }}>
            <Button
              sx={{ flexGrow: 1 }}
              type="submit"
              component={RouterLink}
              href={`${
                paths.home.sendMoney
              }?name=${`${store?.ownerId?.name} ${store?.ownerId?.lastname}`}&cuit=${
                store?.ownerId?.cuit
              }`}
              color="primary"
              variant="contained"
              size="large"
              fullWidth
            >
              Enviá dinero
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
}
