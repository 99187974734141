import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';
// @mui
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
// components
import Image from 'src/commons/components/image';
// ----------------------------------------------------------------------

export default function NoMovements({ image, altImage, title, description, sx }) {
  const theme = useTheme();
  return (
    <Stack
      direction="column"
      sx={{ pt: 3, ...sx }}
      alignItems="center"
      justifyContent="center"
      flexGrow={1}
    >
      <Typography sx={{ color: 'green.dark', textAlign: 'center' }} variant="h2" gutterBottom>
        {title}
      </Typography>
      <Image
        alt={altImage}
        src={image}
        sx={{
          objectPosition: 'center',
        }}
      />
      {description && (
        <Typography
          sx={{ color: theme.palette.grey[700], textAlign: 'center', width: 1 }}
          variant="description"
          gutterBottom
        >
          {description}
        </Typography>
      )}
    </Stack>
  );
}

NoMovements.propTypes = {
  image: PropTypes.string,
  altImage: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  sx: PropTypes.object,
};

// ----------------------------------------------------------------------
