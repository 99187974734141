export const currencies = [
  {
    id: 1,
    label: 'Pesos',
    value: 'ARS',
    icon: 'P',
  },
  {
    id: 2,
    label: 'Crédito Activa',
    value: 'ARS-MA-ANR-ACTIVA',
    icon: 'A',
  },
  {
    id: 3,
    label: 'Crédito Turismo',
    value: 'ARS-MA-ANR-TURISMO',
    icon: 'T',
  },
  {
    id: 4,
    label: 'Crédito Fiscal',
    value: 'ARS-MA-ANR-FISCAL',
    icon: 'F',
  },
];


