// utils
import axiosFeaturesInstance, { endpoints } from './axios';

const listFields = async () => {
  try {
    const response = await axiosFeaturesInstance(endpoints.configs.listFields);
    return response;
  } catch (error) {
    console.error('Error during list fields options:', error);
    throw error;
  }
};

export const configs = { listFields };
