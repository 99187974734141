import PropTypes from 'prop-types';
import { FormProvider as Form } from 'react-hook-form';

export default function FormProvider({ children, onSubmit, methods, fullHeight }) {
  const formStyle = fullHeight ? { height: '100%', width: '100%' } : {};

  return (
    <Form {...methods}>
      <form onSubmit={onSubmit} style={formStyle}>
        {children}
      </form>
    </Form>
  );
}

FormProvider.propTypes = {
  children: PropTypes.node,
  methods: PropTypes.object,
  onSubmit: PropTypes.func,
  fullHeight: PropTypes.bool,
};
