import { useState, useEffect } from 'react';
// utils
import { useParams } from 'src/routes/hook';
// services
import { services } from 'src/services/api';
// components
import { useSnackbar } from 'src/commons/components/snackbar';
import StoreNewEditForm from '../components/store-new-edit-form';

// ----------------------------------------------------------------------

export default function EditStore() {
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const { id } = params;
  const [store, setStore] = useState(null);

  useEffect(() => {
    const fetchStore = async () => {
      try {
        const res = await services.features.myStores.getById(id);
        if (res.success) setStore(res?.data);
      } catch (error) {
        const errorMessage = typeof error === 'string' ? error : error.message;
        enqueueSnackbar(errorMessage, { variant: 'error' });
      }
    };

    fetchStore();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return <StoreNewEditForm currentStore={store} isEdit />;
}
