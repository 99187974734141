import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { FormProvider } from 'src/features/stores/context/form-provider';
// context
import { StepStoresProvider } from 'src/features/stores/context/step-context';
// components
import CreateStore from 'src/features/stores/pages/create-store';
import EditStore from 'src/features/stores/pages/edit-store';
import StoreDetails from 'src/features/stores/pages/store-details';
import { LoadingScreen } from 'src/commons/components/loading-screen';

// ----------------------------------------------------------------------

// OVERVIEW
const IndexPage = lazy(() => import('src/features/stores'));

// ----------------------------------------------------------------------

export const storesRoutes = [
  {
    path: '/my-stores',
    element: (
      <Suspense fallback={<LoadingScreen />}>
        <Outlet />
      </Suspense>
    ),
    children: [
      {
        element: <IndexPage />,
        index: true,
      },
      {
        path: 'create',
        element: (
          <FormProvider>
            <StepStoresProvider>
              <CreateStore />
            </StepStoresProvider>
          </FormProvider>
        ),
      },
      {
        path: ':id',
        element: <StoreDetails />,
      },
      {
        path: ':id/edit',
        element: (
          <FormProvider>
            <StepStoresProvider>
              <EditStore />
            </StepStoresProvider>
          </FormProvider>
        ),
      },
    ],
  },
];
