// utils
import axiosFeaturesInstance, { endpoints } from './axios';

const getPublicVapidKey = async () => {
  try {
    const response = await axiosFeaturesInstance(`${endpoints.webPush.getPublicVapidKey}`);
    return response;
  } catch (error) {
    console.error('Error during wallet list:', error);
    throw error;
  }
};

const create = async (data) => {
  try {
    const response = await axiosFeaturesInstance.post(endpoints.webPush.createSubscription, data);
    return response;
  } catch (error) {
    console.error('Error during create wallet:', error);
    throw error;
  }
};

const removeSuscription = async () => {
  try {
    const response = await axiosFeaturesInstance.delete(endpoints.webPush.removeSubscription);
    return response;
  } catch (error) {
    console.error('Error during create wallet:', error);
    throw error;
  }
};

export const webPush = { create, getPublicVapidKey, removeSuscription };
