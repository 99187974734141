import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useState, useContext, useMemo, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
// services
import { services } from 'src/services/api';
// routes
import { useRouter } from 'src/routes/hook';
import { paths } from 'src/routes/paths';
// components
import StatusScreen from 'src/features/transaction';
import RHFGoogleMaps from 'src/commons/components/hook-form/rhf-google-maps';
import { useSnackbar } from 'src/commons/components/snackbar';
import FormProvider, { RHFTextField } from 'src/commons/components/hook-form';
// context
import { useAuthContext } from 'src/commons/contexts/auth/hooks';
import StepContext from '../context/step-context';
import { useFormContext } from '../context/form-context';
import AddressMap from '../components/address-map';

// ----------------------------------------------------------------------

export default function SelectAddress({ currentStore }) {
  const { setStepTitle, setPreviousStep, handleNext } = useContext(StepContext);
  const formData = useFormContext();
  const router = useRouter();

  const { user } = useAuthContext();
  const { enqueueSnackbar } = useSnackbar();
  const [location, setLocation] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);
  const [showStatusScreen, setShowStatusScreen] = useState(false);

  const SelectAddressSchema = Yup.object().shape({
    address: Yup.mixed().nullable(),
    description: Yup.string()
      .min(1, 'La descripción debe tener al menos 4 caracteres')
      .max(100, 'La descripción no puede exceder los 100 caracteres'),
  });

  const defaultValues = useMemo(
    () => ({
      address: currentStore?.address || '',
      description: currentStore?.description || '',
    }),
    [currentStore]
  );

  const methods = useForm({
    mode: 'onChange',
    delayError: 500,
    resolver: yupResolver(SelectAddressSchema),
    defaultValues,
  });

  const {
    reset,
    setValue,
    handleSubmit,
    formState: { isSubmitting, isValid },
  } = methods;

  useEffect(() => {
    if (currentStore) {
      reset(defaultValues);
    }
  }, [currentStore, defaultValues, reset, setValue]);

  useEffect(() => {
    setStepTitle('');
    setPreviousStep('selectWallets');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateLocation = (lat, lng) => {
    setLocation({ lat, lng });
  };

  const onSubmit = handleSubmit(async (data) => {
    try {
      const storeData = {
        name: formData?.name,
        description: data?.description,
        wallets: formData?.wallets,
        address: data?.address?.description,
        ownerId: user?.id,
        phone: formData?.phone,
        managerId: user?.id,
        fields: formData?.fields,
      };
      if (currentStore) {
        delete storeData.phone;
        const res = await services.features.myStores.update(storeData, currentStore?._id);
        if (res.success) {
          setTimeout(() => {
            setShowStatusScreen(false);
            router.push(paths.home.stores.root);
          }, 3000);
        }
        setShowStatusScreen(true);
      } else {
        const res = await services.features.myStores.create(storeData);
        if (res.success) {
          setTimeout(() => {
            setShowStatusScreen(false);
            handleNext('successfullyStoreCreated');
          }, 3000);
        }
        setShowStatusScreen(true);
      }
    } catch (err) {
      const errorMessage = typeof error === 'string' ? err : err.message;
      enqueueSnackbar(errorMessage, { variant: 'error' });
      setShowStatusScreen(true);
      setTimeout(() => {
        setShowStatusScreen(false);
      }, 3000);
      setErrorMsg(errorMessage);
    }
  });

  return (
    <>
      {showStatusScreen ? (
        <StatusScreen isSuccess={!errorMsg} />
      ) : (
        <Stack alignItems="center" sx={{ height: 1, width: 1, px: 2, pb: 3, pt: 2 }}>
          <Typography
            fontSize={28}
            fontWeight="fontWeightExtraBold"
            sx={{ color: 'common.black', textAlign: 'center', mb: 3 }}
            variant="h2"
          >
            {`Ahora puedes\nagregar el local\nal`}{' '}
            <Box component="span" sx={{ color: 'green.primary', fontSize: 28 }}>
              mapa
            </Box>
          </Typography>
          <FormProvider fullHeight methods={methods} onSubmit={onSubmit}>
            <Stack direction="column" justifyContent="space-between" sx={{ width: 1, height: 1 }}>
              <Stack
                direction="column"
                alignItems="center"
                flexGrow={1}
                spacing={2}
                sx={{ width: 1 }}
              >
                <RHFGoogleMaps
                  defaultValue={currentStore?.address}
                  name="address"
                  label="Dirección"
                  onLocationChange={updateLocation}
                />
                <Box
                  component="div"
                  sx={{
                    width: 1,
                    height: '30vh',
                    position: 'relative',
                  }}
                >
                  <AddressMap
                    latitude={location?.lat}
                    longitude={location?.lng}
                    isLoading={!location}
                  />
                </Box>
                <RHFTextField multiline rows={3} name="description" label="Descripción" fullWidth />
              </Stack>
              <Box sx={{ mt: 6, width: 1, alignSelf: 'flex-end' }}>
                <LoadingButton
                  fullWidth
                  disabled={!isValid}
                  type="submit"
                  color="primary"
                  variant="contained"
                  size="large"
                  loading={isSubmitting}
                >
                  Confirmar
                </LoadingButton>
              </Box>
            </Stack>
          </FormProvider>
        </Stack>
      )}
    </>
  );
}

SelectAddress.propTypes = {
  currentStore: PropTypes.object,
};
