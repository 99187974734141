import axiosFeaturesInstance, { endpoints } from './axios';

const list = async (fields, city, currency) => {
  try {
    const queryParams = new URLSearchParams();

    if (fields && fields.length > 0) {
      queryParams.append('fields', fields.join(','));
    }
    if (city) {
      queryParams.append('city', city);
    }
    if (currency) {
      queryParams.append('currency', currency);
    }

    const response = await axiosFeaturesInstance.get(
      `${endpoints.storesAndPromos.list}?${queryParams.toString()}`
    );

    return response;
  } catch (error) {
    console.error('Error listing stores and promos:', error);
    throw error;
  }
};

const listPromos = async () => {
  try {
    const response = await axiosFeaturesInstance.get(endpoints.storesAndPromos.listPromos);
    return response;
  } catch (error) {
    console.error('Error during list promos:', error);
    throw error;
  }
};

const getById = async (id) => {
  try {
    const response = await axiosFeaturesInstance(`${endpoints.storesAndPromos.list}/${id}`);
    return response;
  } catch (error) {
    console.error('Error during store list:', error);
    throw error;
  }
};

const getByName = async (name) => {
  try {
    const response = await axiosFeaturesInstance(endpoints.storesAndPromos.list, {
      params: { name },
    });
    return response;
  } catch (error) {
    console.error('Error during store list by name:', error);
    throw error;
  }
};

export const storesAndPromos = {
  list,
  listPromos,
  getById,
  getByName,
};
