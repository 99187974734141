import { Helmet } from 'react-helmet-async';
// @mui
import { Stack, Box, Divider, Link, Button } from '@mui/material';
// components
import { RouterLink } from 'src/routes/components';
// assets
import ActionableItem from 'src/features/more/components/actionable-item';
import Header from 'src/commons/layouts/wrapper/header';

// ----------------------------------------------------------------------

export default function Configurations() {
  return (
    <>
      <Helmet>
        <title> Hablar con Soporte </title>
      </Helmet>
      <Header title="Hablar con Soporte" />
      <Stack component="main" direction="column" justifyContent="space-between">
        <Stack px={2} mt={5}>
          <Stack spacing={2.5}>
            <Button
              posible
              solucion
              onClick={() => window.open('mailto:ayuda@tarjetapalta.com?subject=Necesito%20Ayuda!')}
            >
              <ActionableItem
                title="Enviar un email"
                description={`Plazo de respuesta: 24hs habiles\nayuda@tarjetapalta.com`}
                icon="ic:outline-email"
                arrowIcon
              />
            </Button>
            <Divider light />
            <Link
              component={RouterLink}
              href="mailto:ayuda@tarjetapalta.com?subject=Necesito%20Ayuda!"
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                cursor: 'pointer',
                textDecoration: 'none',
                color: 'inherit',
                ':hover': { textDecoration: 'none' },
                ':focus': { textDecoration: 'none' },
              }}
            >
              <ActionableItem
                title="Enviar un email"
                description={`Plazo de respuesta: 24hs habiles\nayuda@tarjetapalta.com`}
                icon="ic:outline-email"
                arrowIcon
              />
            </Link>
            <Divider light />
            <Link
              component={RouterLink}
              href="https://wa.me/+5492613665216?text=Hola%20Palta!%20Necesito%20ayuda"
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                cursor: 'pointer',
                textDecoration: 'none',
                color: 'inherit',
                ':hover': { textDecoration: 'none' },
                ':focus': { textDecoration: 'none' },
              }}
            >
              <ActionableItem
                title="Hablar por WhatsApp"
                description="Horario de atención: 9 a 17hs"
                icon="ic:baseline-whatsapp"
                arrowIcon
              />
            </Link>
          </Stack>
        </Stack>
        <Box
          sx={{
            flexGrow: 1,
            p: 2,
          }}
        />
      </Stack>
    </>
  );
}
