import { createContext, useState, useMemo } from 'react';
import { useNavigate } from 'react-router';
import PropTypes from 'prop-types';
import { paths } from 'src/routes/paths';

const StepContext = createContext({
  handleNext: () => {},
  handleBack: () => {},
});

export const StepStoresProvider = ({ children }) => {
  const [activeStep, setActiveStep] = useState('newStoreInfo');
  const [stepTitle, setStepTitle] = useState('');
  const [previousStep, setPreviousStep] = useState(null);
  const navigate = useNavigate();

  const handleNext = (step) => {
    setActiveStep(step);
  };

  const handleBack = () => {
    if (activeStep === 'newStoreInfo' || previousStep === 'goBack') {
      navigate(paths.home.stores.root);
    } else {
      setActiveStep(previousStep);
    }
  };

  const value = useMemo(
    () => ({
      activeStep,
      handleNext,
      handleBack,
      setPreviousStep,
      stepTitle,
      setStepTitle,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeStep, stepTitle]
  );

  return <StepContext.Provider value={value}>{children}</StepContext.Provider>;
};

StepStoresProvider.propTypes = {
  children: PropTypes.node,
};

export default StepContext;
