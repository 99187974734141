import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
// @mui
import { Stack, Box, Typography } from '@mui/material';
// components
// services
import { services } from 'src/services/api';
// assets
import Header from 'src/commons/layouts/wrapper/header';
import * as cuit from '@frani/cuit';

// ----------------------------------------------------------------------

const TextBox = ({ label = '', value = '-', labelSx = {}, valueSx = {} }) => (
  <Box
    sx={{
      display: 'flex-inline',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      p: 2,
      border: 1,
      borderRadius: 1,
      borderColor: 'grey.400',
    }}
  >
    <Typography variant="body2" sx={{ fontWeight: 700, letterSpacing: 0.25, ...labelSx }}>
      {label}
    </Typography>
    <Typography variant="body2" sx={{ color: 'grey.600', ...valueSx }}>
      {value || '-'}
    </Typography>
  </Box>
);
TextBox.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  labelSx: PropTypes.object,
  valueSx: PropTypes.object,
};

// ----------------------------------------------------------------------

export default function Configurations() {
  const [user, setUser] = useState({
    name: '-',
    lastname: '-',
    email: '-',
    phone: '-',
    dni: '-',
    cuit: '-',
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await services.features.more.getUser();
        setUser(response.data);
      } catch (error) {
        console.error('Error during API call:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <Helmet>
        <title> Tus datos </title>
      </Helmet>
      <Header title="Tus datos" />
      <Typography variant="h6" sx={{ px: 2, fontWeight: 500, color: 'grey.500' }}>
        Aqui solo puedes ver los datos de tu cuenta
        <Box sx={{ color: 'common.black', display: 'inline' }}> Palta</Box>
      </Typography>
      <Stack component="main" direction="column" justifyContent="space-between">
        <Stack px={2} mt={5}>
          <Stack spacing={2.5}>
            <TextBox
              label="Nombre y Apellido"
              valueSx={{ textTransform: 'capitalize' }}
              value={`${user?.name} ${user?.lastname}`}
            />
            <TextBox label="Email" value={user.email} />
            <TextBox label="Teléfono" value={user.phone} />
            <TextBox label="DNI asociada a la cuenta" value={user.dni} />
            <TextBox label="CUIT de la cuenta" value={cuit.format(user.cuit)} />
          </Stack>
        </Stack>
        <Box
          sx={{
            flexGrow: 1,
            p: 2,
          }}
        />
      </Stack>
    </>
  );
}
