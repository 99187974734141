// utils
import axiosFeaturesInstance, { endpoints } from './axios';

const listWallets = async () => {
  try {
    const response = await axiosFeaturesInstance(endpoints.servicePayment.listWallets);
    return response;
  } catch (error) {
    console.error('Error during service payment wallets list:', error);
    throw error;
  }
};

const getOptions = async (data) => {
  try {
    const response = await axiosFeaturesInstance.post(endpoints.servicePayment.getOptions, data);
    return response;
  } catch (error) {
    console.error('Error during get options:', error);
    throw error;
  }
};

const getCompany = async ({ name, code }) => {
  try {
    const params = {};

    if (name) {
      params.name = name;
    } else if (code) {
      params.code = code;
    } else {
      throw new Error('Either name or code must be provided.');
    }

    const response = await axiosFeaturesInstance(endpoints.servicePayment.getCompany, {
      params,
    });

    return response;
  } catch (error) {
    console.error('Error during get company:', error);
    throw error;
  }
};

const pay = async (data) => {
  try {
    const response = await axiosFeaturesInstance.post(endpoints.servicePayment.pay, data);
    return response;
  } catch (error) {
    console.error('Error when paying service:', error);
    throw error;
  }
};

const getBill = async (barcode) => {
  try {
    const response = await axiosFeaturesInstance(
      `${endpoints.servicePayment.getBill}?barcode=${barcode}`
    );
    return response;
  } catch (error) {
    console.error('Error during get bill:', error);
    throw error;
  }
};

export const servicePayment = { listWallets, getCompany, getOptions, pay, getBill };
