// @mui
import { useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { RouterLink } from 'src/routes/components';
// routes
import { paths } from 'src/routes/paths';
// components
import Image from 'src/commons/components/image';
import HeaderSubtitle from 'src/commons/components/header-subtitle/header-subtitle';
import { userExists } from './assets/illustrations';

// ----------------------------------------------------------------------

export default function UserAlreadyExistsView() {
  const theme = useTheme();

  return (
    <Stack
      direction="column"
      sx={{
        height: 1,
      }}
    >
      <Stack direction="column" alignItems="center" justifyContent="center" flexGrow={1}>
        <Image
          alt="Usuario ya registrado"
          src={userExists}
          sx={{
            objectPosition: 'center',
          }}
        />
        <HeaderSubtitle
          title="Usuario ya registrado"
          subtitle="Por favor, inicia sesión en lugar de crear una nueva cuenta."
          alignCenter
        />
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        sx={{ px: 1, color: 'common.white' }}
      >
        <Button
          fullWidth
          component={RouterLink}
          href={paths.auth.login}
          color="inherit"
          sx={{ fontWeight: theme.typography.fontWeightMedium, textAlign: 'center' }}
          variant="contained"
          size="large"
        >
          Ingresa a la App
        </Button>
      </Stack>
    </Stack>
  );
}

// ----------------------------------------------------------------------
