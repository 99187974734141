import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { GuestGuard } from 'src/commons/contexts/auth/guard';
// layouts
import WrapperLayout from 'src/commons/layouts/wrapper/layout';
// components
import { SplashScreen } from 'src/commons/components/loading-screen';
// context
import { StepLoginProvider } from 'src/features/login/context/step-context';
import { StepRegisterProvider } from 'src/features/register/context/step-context';
import UserAlreadyExists from 'src/pages/auth/jwt/user-already-exists';
import { paths } from '../paths';
// ----------------------------------------------------------------------
// JWT
const WelcomePage = lazy(() => import('src/pages/auth/jwt/welcome'));
const LoginPage = lazy(() => import('src/pages/auth/jwt/login'));
const RegisterPage = lazy(() => import('src/pages/auth/jwt/register'));
const ForgetPinPage = lazy(() => import('src/pages/auth/jwt/forget-pin'));

// ----------------------------------------------------------------------

export const authRoutes = [
  {
    path: 'auth',
    element: (
      <GuestGuard>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </GuestGuard>
    ),
    children: [
      {
        path: 'welcome',
        element: <WelcomePage />,
      },
      {
        path: 'login',
        element: (
          <StepLoginProvider>
            <LoginPage />
          </StepLoginProvider>
        ),
      },
      {
        path: 'register',
        element: (
          <StepRegisterProvider>
            <RegisterPage />
          </StepRegisterProvider>
        ),
      },
      {
        path: 'forget-pin',
        element: (
          <WrapperLayout title="Olvidé mi Pin">
            <ForgetPinPage />
          </WrapperLayout>
        ),
      },
      {
        path: 'user-already-exists',
        element: (
          <WrapperLayout title="Usuario ya registrado" path={paths.auth.welcome}>
            <UserAlreadyExists />
          </WrapperLayout>
        ),
      },
    ],
  },
];
