import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';
import _ from 'lodash';
// @mui
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';

import { RouterLink } from 'src/routes/components';
// routes
import { useRouter, useParams } from 'src/routes/hook';
import { paths } from 'src/routes/paths';
// components
import Image from 'src/commons/components/image';
import { useSnackbar } from 'src/commons/components/snackbar';
import { HeaderSubtitle, HeaderSubtitleSkeleton } from 'src/commons/components/header-subtitle';
// services
import { services } from 'src/services/api';
// assets
import { hideWallet } from '../assets/illustrations';

// ----------------------------------------------------------------------

export default function HideWalletPage() {
  const params = useParams();
  const { id } = params;
  const { enqueueSnackbar } = useSnackbar();
  const router = useRouter();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [wallet, setWallet] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchWallet = async () => {
      try {
        setIsLoading(true);
        const res = await services.features.wallets.getById(id);
        if (res.success) setWallet(res?.data);
      } catch (err) {
        console.error('Error fetching wallet information', err);
      } finally {
        setIsLoading(false);
      }
    };
    fetchWallet();
  }, [id]);

  const handleHideWallet = async () => {
    try {
      setIsSubmitting(true);
      const res = await services.features.wallets.hideWallet(id, !wallet?.isHidden);
      if (res.success) {
        enqueueSnackbar(
          `${wallet?.isHidden ? 'Mostraste' : 'Ocultaste'} la ${_.capitalize(
            wallet?.currency?.alternativeName
          )}`,
          {
            variant: wallet?.isHidden ? 'success' : 'error',
          }
        );
        router.push(paths.home.wallets.root);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Helmet>
        <title> Ocultar Billetera </title>
      </Helmet>

      <Stack
        component="main"
        direction="column"
        justifyContent="space-between"
        sx={{
          position: 'absolute',
          inset: 0,
          height: '100vh',
          p: 2,
        }}
      >
        <Stack
          direction="column"
          sx={{ color: 'common.white' }}
          alignItems="center"
          justifyContent="center"
          flexGrow={1}
        >
          <Image
            alt="Ocultar billetera"
            src={hideWallet}
            sx={{
              objectPosition: 'center',
            }}
          />
          {isLoading ? (
            <HeaderSubtitleSkeleton alignCenter />
          ) : (
            <HeaderSubtitle
              color="green.dark"
              title={`¿Quieres ${wallet?.isHidden ? 'Mostrar' : 'Ocultar'} tu Billetera?`}
              subtitle={`Tu Billetera ${_.capitalize(wallet?.currency?.alternativeName)} ${
                wallet?.isHidden ? 'estará visible' : 'dejará de estar visible'
              } dentro de Palta`}
              alignCenter
            />
          )}
        </Stack>

        <Stack
          direction="column"
          alignItems="center"
          spacing={2}
          justifyContent="center"
          sx={{ px: 1, color: 'common.white' }}
        >
          <LoadingButton
            loading={isSubmitting}
            onClick={handleHideWallet}
            fullWidth
            size="large"
            color="primary"
            variant="contained"
            sx={{ width: 1 }}
          >
            {`${wallet?.isHidden ? 'Mostrar' : 'Ocultar'} billetera`}
          </LoadingButton>
          <Button
            component={RouterLink}
            href={paths.home.wallets.root}
            fullWidth
            size="large"
            variant="outlined"
            sx={{ width: 1, color: 'common.black', fontWeight: 'fontWeightMedium' }}
          >
            Cancelar
          </Button>
        </Stack>
      </Stack>
    </>
  );
}

// ----------------------------------------------------------------------
