import { createContext, useState, useMemo } from 'react';
import { useNavigate } from 'react-router';
import PropTypes from 'prop-types';
import { paths } from 'src/routes/paths';

const StepContext = createContext({
  handleNext: () => {},
  handleBack: () => {},
});

export const StepServicePaymentProvider = ({ children }) => {
  const [activeStep, setActiveStep] = useState('selectPayment');
  const [stepTitle, setStepTitle] = useState('Pago de servicios');
  const [previousStep, setPreviousStep] = useState(null); // Track the previous step
  const navigate = useNavigate();

  const handleNext = (step) => {
    setActiveStep(step);
  };

  const handleBack = () => {
    if (activeStep === 'selectPayment') {
      navigate(paths.home.root);
    } else {
      setActiveStep(previousStep);
    }
  };

  const value = useMemo(
    () => ({
      activeStep,
      handleNext,
      handleBack,
      setPreviousStep,
      stepTitle,
      setStepTitle,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeStep, stepTitle]
  );

  return <StepContext.Provider value={value}>{children}</StepContext.Provider>;
};

StepServicePaymentProvider.propTypes = {
  children: PropTypes.node,
};

export default StepContext;
