import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// components
import { LoadingScreen } from 'src/commons/components/loading-screen';
// context
import { StepQrProvider } from 'src/features/send-money/context/step-context';
import { FormProvider } from 'src/features/send-money/context/form-provider';

// ----------------------------------------------------------------------

// OVERVIEW
const IndexPage = lazy(() => import('src/features/send-money'));

// ----------------------------------------------------------------------

export const sendMoneyRoutes = [
  {
    path: '/send-money',
    element: (
      <Suspense fallback={<LoadingScreen />}>
        <Outlet />
      </Suspense>
    ),
    children: [
      {
        element: (
          <FormProvider>
            <StepQrProvider>
              <IndexPage />
            </StepQrProvider>
          </FormProvider>
        ),
        index: true,
      },
    ],
  },
];
