import { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
// @mui
import { styled, useTheme, alpha } from '@mui/material/styles';
import Slide from '@mui/material/Slide';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
// routes
import { useRouter } from 'src/routes/hook';
// components
import Iconify from 'src/commons/components/iconify';
// icons
import { qr } from '../../assets/icons';

// ----------------------------------------------------------------------
const TABS = [
  {
    value: '/',
    icon: <Iconify icon="ph:house-light" width={24} />,
    label: 'Inicio',
  },
  {
    value: '/history',
    icon: <Iconify icon="basil:invoice-outline" width={24} />,
    label: 'Historial',
  },
  {
    value: '/qr-payment',
    icon: <Box component="img" src={qr} />,
    largeBtn: true,
  },
  {
    value: '/wallets',
    icon: <Iconify icon="iconoir:wallet" width={24} />,
    label: 'Billeteras',
  },
  {
    value: '/more',
    icon: <Iconify icon="ic:round-menu" width={24} />,
    label: 'Más',
  },
];
// ----------------------------------------------------------------------

const StyledTabs = styled((props) => (
  <Tabs {...props} TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }} />
))(({ theme }) => ({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-indicatorSpan': {
    maxWidth: 10,
    borderRadius: 1,
    width: 10,
    backgroundColor: theme.palette.green.primary,
  },
}));

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
  textTransform: 'none',
  fontWeight: theme.typography.fontWeightMedium,
  fontSize: theme.typography.pxToRem(12),
  color: theme.palette.grey[650],
  '&.Mui-selected': {
    paddingBottom: 8,
    color: theme.palette.common.black,
    '& .MuiTab-iconWrapper': {
      fill: theme.palette.green.primary,
      color: theme.palette.green.primary,
    },
  },
  '&.Mui-focusVisible': {
    backgroundColor: 'rgba(100, 95, 228, 0.32)',
  },
}));
// ----------------------------------------------------------------------

const useScrollDirection = () => {
  const [isScrollingDown, setIsScrollingDown] = useState(false);
  const [prevScrollY, setPrevScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      if (currentScrollY > prevScrollY) {
        setIsScrollingDown(true);
      } else {
        setIsScrollingDown(false);
      }

      setPrevScrollY(currentScrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [prevScrollY]);

  return isScrollingDown;
};

// ----------------------------------------------------------------------

export default function AppBar() {
  const router = useRouter();
  const location = useLocation();
  const theme = useTheme();
  const [currentTab, setCurrentTab] = useState('/');

  const isScrollingDown = useScrollDirection();

  const handleChangeTab = useCallback(
    (event, newValue) => {
      setCurrentTab(newValue);
      router.push(newValue);
    },
    [router]
  );

  useEffect(() => {
    const path = location.pathname;
    const selectedTab = TABS.find((tab) => {
      const cleanTabValue = tab.value.replace(/\/$/, '');
      const cleanPath = path.replace(/\/$/, '');
      return cleanPath === cleanTabValue;
    });

    if (selectedTab) {
      setCurrentTab(selectedTab.value);
    }
  }, [location]);

  return (
    <Slide direction="up" in={!isScrollingDown} mountOnEnter unmountOnExit>
      <Box
        sx={{
          px: 1,
          pb: 2,
          width: 1,
          position: 'fixed',
          bottom: 0,
          zIndex: 6,
        }}
      >
        <Box
          alignItems="center"
          justifyContent="center"
          sx={{
            display: 'flex',
            width: 1,
            borderRadius: 1,
            height: 80,
            backgroundColor: 'common.white',
            border: 1,
            borderColor: 'grey.150',
            boxShadow: `0px 10px 60px 5px ${alpha(theme.palette.common.black, 0.4)}`,
          }}
        >
          <StyledTabs value={currentTab} centered onChange={handleChangeTab}>
            {TABS?.map((tab) => (
              <StyledTab
                key={tab?.value}
                icon={tab?.icon}
                label={tab?.label}
                iconPosition="top"
                value={tab?.value}
              />
            ))}
          </StyledTabs>
        </Box>
      </Box>
    </Slide>
  );
}
