import { Helmet } from 'react-helmet-async';
// sections
import UserAlreadyExistsView from 'src/features/user-already-exists';

// ----------------------------------------------------------------------

export default function UserAlreadyExists() {
  return (
    <>
      <Helmet>
        <title> Usuario ya registrado </title>
      </Helmet>

      <UserAlreadyExistsView />
    </>
  );
}
