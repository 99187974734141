import { Helmet } from 'react-helmet-async';
// sections
import EditWalletForm from '../components/edit-wallet-form';

// ----------------------------------------------------------------------

export default function EditWalletPage() {
  return (
    <>
      <Helmet>
        <title> Editar Billetera </title>
      </Helmet>

      <EditWalletForm />
    </>
  );
}
