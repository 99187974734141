import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet-async';
// sections
import UnlockView from 'src/features/unlock';
// ----------------------------------------------------------------------

export default function UnlockPage({ onUnlock }) {
  return (
    <>
      <Helmet>
        <title> Desbloquear </title>
      </Helmet>

      <UnlockView />
    </>
  );
}

UnlockPage.propTypes = {
  onUnlock: PropTypes.func
}
