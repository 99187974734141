import { DateTime } from 'luxon';
// coins
import { coinActiva, coinTurismo, coinFiscal, coinPesos } from '../../assets/coins';

export const convertDate = (inputDate) => {
  const dateTimeObject = DateTime.fromISO(inputDate, { zone: 'utc' });
  const formattedDate = dateTimeObject.toFormat('dd LLL');

  return formattedDate;
};

export const getCoinImage = (currency) => {
  switch (currency) {
    case 'credito fiscal':
      return coinFiscal;
    case 'credito turismo':
      return coinTurismo;
    case 'credito activa':
      return coinActiva;
    case 'pesos':
      return coinPesos;
    default:
      // If the currency doesn't match any of the above cases, you can return a default image or null.
      return null;
  }
};

export const extractInitials = (fullName) => {
  const trimmedFullName = fullName.trim();
  const namesArray = trimmedFullName.split(' ');

  const firstNameInitial = namesArray[1]?.substring(0, 1) || '';
  const lastNameInitial =
    namesArray.length > 1 ? namesArray[namesArray.length - 1]?.substring(0, 1) || '' : '';

  return String(firstNameInitial + lastNameInitial).toUpperCase();
};

export const stringToColor = (string) => {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
};

export const getContrastColor = (hexColor) => {
  // Convert hex to RGB
  const r = parseInt(hexColor.slice(1, 3), 16);
  const g = parseInt(hexColor.slice(3, 5), 16);
  const b = parseInt(hexColor.slice(5, 7), 16);

  // Calculate relative luminance
  const luminance =
    0.2126 * (r / 255) ** 2.2 + 0.7152 * (g / 255) ** 2.2 + 0.0722 * (b / 255) ** 2.2;

  // Use black for lighter colors, white for darker colors
  return luminance > 0.5 ? 'black' : 'white';
};
