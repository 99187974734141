import PropTypes from 'prop-types';
import { useEffect, useCallback, useState } from 'react';
import { DateTime } from 'luxon';
// paths
import { paths } from 'src/routes/paths';
// hooks
import { useRouter } from 'src/routes/hook';
import { useAuthContext } from '../hooks';

// ----------------------------------------------------------------------

// Constants
const UNLOCK_DURATION = 5 * 60 * 1000; // 5 minutes in milliseconds

const loginPaths = {
  jwt: paths.auth.welcome,
};

function calculateElapsedTime(unlockTimestamp) {
  const currentTime = DateTime.now().toMillis();
  return currentTime - parseInt(unlockTimestamp, 10);
}

function shouldAllowAccess(elapsedTime) {
  return elapsedTime <= UNLOCK_DURATION;
}

// ----------------------------------------------------------------------

export default function AuthGuard({ children }) {
  const router = useRouter();
  const { authenticated, method } = useAuthContext();
  const [checked, setChecked] = useState(false);
  const [timer, setTimer] = useState(null);

  const check = useCallback(() => {
    if (!authenticated) {
      const searchParams = new URLSearchParams({ returnTo: window.location.pathname }).toString();
      const loginPath = loginPaths[method];
      const href = `${loginPath}?${searchParams}`;
      router.replace(href);
      return;
    }

    const validateAccess = () => {
      const unlockTimestamp = sessionStorage.getItem('unlockTimestamp');
      if (!unlockTimestamp || !shouldAllowAccess(calculateElapsedTime(unlockTimestamp))) {
        router.replace(paths.home.unlock);
      }
    };

    const resetUnlockTimestamp = () => {
      const newUnlockTimestamp = DateTime.now().toMillis();
      sessionStorage.setItem('unlockTimestamp', newUnlockTimestamp);
    };

    if (timer) {
      clearInterval(timer);
    }

    const newTimer = setInterval(resetUnlockTimestamp, 30000);
    setTimer(newTimer);

    validateAccess();

    setChecked(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticated, method, router]);

  useEffect(() => {
    check();
  }, [check]); // Add check as a dependency

  if (!checked) {
    return null;
  }

  return <>{children}</>;
}

AuthGuard.propTypes = {
  children: PropTypes.node,
};