import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/commons/contexts/auth/guard';
// components
import { LoadingScreen } from 'src/commons/components/loading-screen';
import WrapperLayout from 'src/commons/layouts/wrapper/layout';
import { paths } from '../paths';

// ----------------------------------------------------------------------

// OVERVIEW
const IndexPage = lazy(() => import('src/features/notifications'));

// ----------------------------------------------------------------------

export const notificationsRoutes = [
  {
    path: '/notifications',
    element: (
      <AuthGuard>
        <WrapperLayout title="Notificaciones" path={paths.home.root}>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </WrapperLayout>
      </AuthGuard>
    ),
    children: [{ element: <IndexPage />, index: true }],
  },
];
