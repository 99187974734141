import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
// @mui
import { Stack, Box } from '@mui/material';
// components
// assets
import ActionableItem from 'src/features/more/components/actionable-item';
import Header from 'src/commons/layouts/wrapper/header';

// ----------------------------------------------------------------------

export default function Configurations() {
  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title> Ayuda </title>
      </Helmet>
      <Header title="Necesito Ayuda" />
      <Stack component="main" direction="column" justifyContent="space-between">
        <Stack px={2} mt={5}>
          <Stack spacing={2.5}>
            <ActionableItem
              title="Comunicate con Soporte"
              icon="bx:bx-support"
              arrowIcon
              onClick={() => navigate('/more/need-help/supports')}
            />
          </Stack>
        </Stack>
        <Box
          sx={{
            flexGrow: 1,
            p: 2,
          }}
        />
      </Stack>
    </>
  );
}
