import { createContext, useState, useMemo } from 'react';
import { useNavigate } from 'react-router';
import PropTypes from 'prop-types';

const StepContext = createContext({
  handleNext: () => {},
  handleBack: () => {},
});

export const StepPhoneTopupProvider = ({ children }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [stepTitle, setStepTitle] = useState(null)
  const navigate = useNavigate();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    if (activeStep === 0) navigate(-1);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const value = useMemo(
    () => ({
      activeStep,
      handleNext,
      handleBack,
      stepTitle,
      setStepTitle
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeStep]
  );

  return <StepContext.Provider value={value}>{children}</StepContext.Provider>;
};

StepPhoneTopupProvider.propTypes = {
  children: PropTypes.node,
};

export default StepContext;
