import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

// SETUP COLORS

const GREY = {
  0: '#FFFFFF',
  50: '#EBEDEA',
  100: '#F3F3F3',
  125: '#F2F4F7',
  150: '#EDEFF6',
  200: '#DEDEDE',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#717171',
  550: '#78838D',
  600: '#868787',
  650: '#4F4E4D',
  700: '#4D4D4D',
  800: '#212B36',
  850: '#191919',
  900: '#161C24',
};

const PRIMARY = {
  lighter: '#C8FAD6',
  light: '#5BE49B',
  main: '#29CC6A',
  dark: '#146634',
  darker: '#004B50',
  contrastText: '#FFFFFF',
};

const SECONDARY = {
  lighter: '#EFD6FF',
  light: '#C684FF',
  main: '#062013',
  dark: '#031009',
  darker: '#27097A',
  contrastText: '#FFFFFF',
};

const INFO = {
  lighter: '#CAFDF5',
  light: '#61F3F3',
  main: '#00B8D9',
  dark: '#006C9C',
  darker: '#003768',
  contrastText: '#FFFFFF',
};

const SUCCESS = {
  lighter: '#D3FCD2',
  light: '#77ED8B',
  main: '#22C55E',
  dark: '#118D57',
  darker: '#065E49',
  contrastText: '#ffffff',
};

const WARNING = {
  lighter: '#FFF5CC',
  light: '#FFD666',
  main: '#FFAB00',
  dark: '#B76E00',
  darker: '#7A4100',
  contrastText: GREY[800],
};

const ERROR = {
  lighter: '#FFE9D5',
  light: '#FFAC82',
  main: '#FC5555',
  dark: '#B71D18',
  darker: '#7A0916',
  contrastText: '#FFFFFF',
};

const GREEN = {
  backgroundFill: '#0F1605',
  dark: '#062013',
  darkest: '#121212',
  main: '#29CC6A',
  primary: '#29CC6A',
  backgroundBlur: '#020E08',
};

const GREENDARK = {
  main: '#062013',
};

const WALLETS = {
  total: '#FFFFFF',
  pesos: '#EEFFE7',
  activa: '#F1F2FF',
  turista: '#FFEDE3',
  credito: '#FFF6DD',
  green: '#C6EDB4',
  violet: '#8463EA',
  brown: '#E3AD8E',
  yellow: '#FFECBA',
  grey: '#C7C7C7',
};

const ORANGE = '#FF9F1C';

const COMMON = {
  common: {
    black: '#000000',
    white: '#FFFFFF',
  },
  orange: ORANGE,
  primary: PRIMARY,
  secondary: SECONDARY,
  greenDark: GREENDARK,
  info: INFO,
  success: SUCCESS,
  warning: WARNING,
  error: ERROR,
  grey: GREY,
  green: GREEN,
  wallets: WALLETS,
  divider: alpha(GREY[500], 0.2),
  action: {
    hover: alpha(GREY[500], 0.08),
    selected: alpha(GREY[500], 0.16),
    disabled: alpha(GREY[500], 0.8),
    disabledBackground: alpha(GREY[500], 0.24),
    focus: alpha(GREY[500], 0.24),
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

export function palette(mode) {
  const light = {
    ...COMMON,
    mode: 'light',
    text: {
      primary: GREY[800],
      secondary: GREY[600],
      disabled: GREY[500],
    },
    background: {
      paper: '#FFFFFF',
      default: '#FFFFFF',
      neutral: GREY[100],
    },
    action: {
      ...COMMON.action,
      active: GREY[600],
    },
  };

  const dark = {
    ...COMMON,
    mode: 'dark',
    text: {
      primary: '#FFFFFF',
      secondary: GREY[500],
      disabled: GREY[600],
    },
    background: {
      paper: GREY[800],
      default: GREY[900],
      neutral: alpha(GREY[500], 0.12),
    },
    action: {
      ...COMMON.action,
      active: GREY[500],
    },
  };

  return mode === 'light' ? light : dark;
}
