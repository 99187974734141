/* eslint-disable import/no-extraneous-dependencies */
import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
import { DateTime } from 'luxon';
import { useLocation } from 'react-router-dom';
// @mui
import { useTheme, alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Badge from '@mui/material/Badge';
import Typography from '@mui/material/Typography';
// import Button from '@mui/material/Button';
import { fCurrency } from 'src/utils/format-number';
// theme
import { bgGradient } from 'src/config/theme/css';
// helpers
import { useAuthContext } from 'src/commons/contexts/auth/hooks';
import { handleCopyToClipboard } from 'src/commons/utils/helpers';
// components
import { useSnackbar } from 'src/commons/components/snackbar';
import Label from 'src/commons/components/label';
import Iconify from 'src/commons/components/iconify';
import LoadingButton from '@mui/lab/LoadingButton';
import Cashout from './suboperation-items/cashout';
import Cashin from './suboperation-items/cashin';
import Transfer from './suboperation-items/transfer';
// utils
import { currencies } from '../utils/currencies';

// ----------------------------------------------------------------------

function OperationDetailsView({ operation }) {
  const { user } = useAuthContext();
  const location = useLocation();
  const [isDownloading, setIsDownloading] = useState(false);
  const searchParams = new URLSearchParams(location.search);

  const {
    status,
    createdAt,
    params: { amount },
    suboperations,
    _id,
  } = operation;

  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();

  const operationTitle = searchParams.get('title');
  const operationCurrency = searchParams.get('currency');
  const currency = currencies.find((c) => c?.value === operationCurrency);
  const currencyTitle = currency ? currency?.label : operationCurrency;

  const renderDetails = (
    <Stack direction="row" spacing={1.5} alignItems="flex-start" sx={{ width: 1, px: 1.5 }}>
      <Badge
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        overlap="circular"
        badgeContent={
          <Iconify
            icon={`ic:baseline-arrow-${
              operationTitle?.includes('enviaste') ? 'downward' : 'upward'
            }`}
            sx={{
              color: `${operationTitle?.includes('enviaste') ? 'error.main' : 'green.primary'}`,
              backgroundColor: 'common.white',
              borderRadius: 10,
            }}
          />
        }
      >
        <Box
          alignItems="center"
          justifyContent="center"
          sx={{
            borderRadius: 1.5,
            display: 'flex',
            backgroundColor: 'grey.100',
            width: 56,
            height: 56,
          }}
        >
          <Iconify
            icon={`majesticons:money-${
              operationTitle?.includes('enviaste') ? 'minus' : 'plus'
            }-line`}
            width={32}
          />
        </Box>
      </Badge>

      <Stack direction="column" alignItems="flex-start" spacing={0.5}>
        <Typography
          variant="h2"
          fontWeight="fontWeightSemiBold"
          fontSize={20}
          width="1"
          sx={{ color: 'grey.850', textTransform: 'capitalize' }}
        >
          {operationTitle}
        </Typography>

        <Typography
          variant="body2"
          fontWeight="fontWeightRegular"
          sx={{ color: 'grey.550', textTransform: 'capitalize' }}
        >
          {`${operationTitle?.includes('enviaste') ? 'De' : 'En'} tu billetera ${currencyTitle}`}
        </Typography>
      </Stack>
    </Stack>
  );

  const renderAmount = (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{
        ...bgGradient({
          direction: '135deg',
          startColor: alpha(theme.palette.info.light, 0.2),
          endColor: alpha(theme.palette.info.main, 0.2),
        }),
        width: 1,
        p: 1.5,
        borderRadius: 2,
        position: 'relative',
        color: 'info.darker',
        backgroundColor: 'common.white',
      }}
    >
      <Typography variant="h3" sx={{ textAlign: 'center' }}>
        {fCurrency({ number: amount, withSymbol: true })}
      </Typography>
    </Stack>
  );

  let statusMsg;
  let statusColor;
  if (status === 'success') {
    statusMsg = 'Completado';
    statusColor = 'success';
  } else if (status === 'error') {
    statusMsg = 'Error';
    statusColor = 'error';
  } else {
    statusMsg = 'Pendiente';
    statusColor = 'warning';
  }

  const renderStatus = (
    <TextField
      variant="outlined"
      fullWidth
      disabled
      type="text"
      label="Estado"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Label color={statusColor}>{statusMsg}</Label>
          </InputAdornment>
        ),
      }}
    />
  );

  const renderOperationId = (
    <Stack
      sx={{
        width: 1,
        p: 2,
        borderRadius: 1,
        border: `1px solid ${theme.palette.divider}`,
      }}
      direction="row"
    >
      <Stack flexGrow={1} direction="column" alignItems="flex-start">
        <Typography
          variant="caption"
          fontSize={13}
          sx={{ textTransform: 'capitalize', color: 'grey.700' }}
        >
          N° operación
        </Typography>
        <Typography
          variant="body1"
          fontSize={14}
          fontWeight="fontWeightSemiBold"
          sx={{ textTransform: 'capitalize', color: 'green.dark' }}
        >
          #{_id}
        </Typography>
      </Stack>

      <IconButton
        onClick={() => handleCopyToClipboard(_id, ' N° operación', enqueueSnackbar)}
        edge="end"
      >
        <Iconify icon="ri:file-copy-line" width={24} sx={{ color: 'green.primary' }} />
      </IconButton>
    </Stack>
  );

  const renderDate = (
    <Stack
      sx={{
        width: 1,
        p: 2,
        borderRadius: 1,
        border: `1px solid ${theme.palette.divider}`,
      }}
      direction="row"
    >
      <Stack flexGrow={1} direction="column" alignItems="flex-start">
        <Typography
          variant="caption"
          fontSize={13}
          sx={{ textTransform: 'capitalize', color: 'grey.700' }}
        >
          Fecha
        </Typography>
        <Typography
          variant="body1"
          fontSize={14}
          fontWeight="fontWeightSemiBold"
          sx={{ textTransform: 'capitalize', color: 'green.dark' }}
        >
          {DateTime.fromISO(createdAt, { zone: 'utc' }).toFormat('dd/MM/yyyy · HH:mm')}
        </Typography>
      </Stack>
    </Stack>
  );

  const renderSuboperations = (
    <Stack
      direction="column"
      spacing={2}
      sx={{
        width: 1,
      }}
    >
      {suboperations?.cashins?.map((item) => (
        <Cashin key={item?._id} suboperation={item} />
      ))}
      {suboperations?.cashouts?.map((item) => (
        <Cashout key={item?._id} suboperation={item} />
      ))}
      {suboperations?.transfers?.map((item) => (
        <Transfer key={item?._id} suboperation={item} />
      ))}
    </Stack>
  );

  const operationRef = useRef();

  const downloadPDF = async () => {
    setIsDownloading(true);
    try {
      const link = document.createElement('a');
      // Specify the filename for the download
      // const filename = `operation-${DateTime.fromISO(createdAt, { zone: 'utc' }).toFormat(
      //   'dd-MM-yyyy'
      // )}-${_id}.pdf`;
      // link.setAttribute('download', filename);
      link.href = `https://api.palta.app/features/v1/operations/${_id}/${user.id}/receipt.pdf`;
      link.click();
    } catch (error) {
      console.error(error);
    }
    setIsDownloading(false);
  };

  const renderActionButtons = (
    <Stack
      direction="column"
      alignItems="center"
      justifyContent="center"
      spacing={1}
      sx={{ mt: 3, mb: 2 }}
    >
      <LoadingButton
        loading={isDownloading}
        variant="contained"
        size="large"
        color="secondary"
        fullWidth
        onClick={downloadPDF}
        startIcon={<Iconify icon="ph:file-pdf" color={theme.palette.common.white} />}
      >
        Descargar
      </LoadingButton>
    </Stack>
  );

  return (
    <Stack
      direction="column"
      sx={{
        height: 1,
        mt: 2,
        width: 1,
      }}
    >
      <Stack ref={operationRef} direction="column" alignItems="center" spacing={2} flexGrow={1}>
        {renderDetails}
        {renderAmount}
        {renderStatus}
        {renderOperationId}
        {renderDate}
        {renderSuboperations}
      </Stack>
      {renderActionButtons}
    </Stack>
  );
}

OperationDetailsView.propTypes = {
  operation: PropTypes.object,
};

export default OperationDetailsView;
