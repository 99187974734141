import PropTypes from 'prop-types';

import { createContext, useState, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { paths } from 'src/routes/paths';

const StepContext = createContext({
  handleBack: () => {},
  handleNext: () => {},
});

export const StepQrProvider = ({ children }) => {
  const [activeStep, setActiveStep] = useState('sendMoney');
  const navigate = useNavigate();

  const handleBack = (step) => {
    if (activeStep === 'sendMoney') navigate(paths.home.root);
    setActiveStep(step);
  };

  const value = useMemo(
    () => ({
      activeStep,
      handleBack,
      setActiveStep,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeStep]
  );

  return <StepContext.Provider value={value}>{children}</StepContext.Provider>;
};

StepQrProvider.propTypes = {
  children: PropTypes.node,
};

export default StepContext;
