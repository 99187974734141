import PropTypes from 'prop-types';
import { useState, useEffect, useContext } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import { FormControl, FormControlLabel, FormGroup, Checkbox, Button } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
// services
import { services } from 'src/services/api';
// utils
import { getWalletProperties } from 'src/features/wallets/utils/helpers';
// components
import WalletSkeleton from 'src/features/wallets/components/wallet-skeleton';
import { RouterLink } from 'src/routes/components';
import Iconify from 'src/commons/components/iconify';
import { useSnackbar } from 'src/commons/components/snackbar';
// paths
import { paths } from 'src/routes/paths';
// context
import StepContext from '../context/step-context';
import { useFormContext } from '../context/form-context';

// ----------------------------------------------------------------------

export default function SelectWallets({ currentStore }) {
  const theme = useTheme();
  const { setStepTitle, setPreviousStep, handleNext } = useContext(StepContext);
  const formData = useFormContext();
  const { enqueueSnackbar } = useSnackbar();
  const [wallets, setWallets] = useState([]);
  const [walletsLoading, setWalletsLoading] = useState(null);

  useEffect(() => {
    setStepTitle('Asociar billetera');
    setPreviousStep('storeNewEditForm');
    const fetchWallets = async () => {
      setWalletsLoading(true);
      try {
        const response = await services.features.wallets.list();
        if (response.success) {
          setWallets(response.data);
          if (currentStore) {
            const storeWallets = currentStore?.wallets;
            setSelectedWallets(storeWallets?.map((wallet) => wallet._id));
          }
        }
      } catch (err) {
        const errorMessage = typeof error === 'string' ? err : err.message;
        enqueueSnackbar(errorMessage, { variant: 'error' });
      } finally {
        setWalletsLoading(false);
      }
    };
    fetchWallets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [selectedWallets, setSelectedWallets] = useState([]);

  const handleWalletSelection = (walletId) => {
    if (selectedWallets.includes(walletId)) {
      setSelectedWallets(selectedWallets.filter((id) => id !== walletId));
    } else {
      setSelectedWallets([...selectedWallets, walletId]);
    }
  };

  const renderPaymentMethods = (
    <FormControl sx={{ width: 1, mt: 4, flexGrow: 1 }}>
      <FormGroup>
        {wallets?.map((wallet) => {
          const { currency, _id, name } = wallet;
          const { backgroundColor, icon: walletIcon } = getWalletProperties(
            theme,
            currency?.alternativeName,
            true
          );

          return (
            <FormControlLabel
              key={_id}
              control={
                <Checkbox
                  checked={selectedWallets.includes(_id)}
                  onChange={() => handleWalletSelection(_id)}
                />
              }
              label={
                <Stack
                  direction="row"
                  sx={{ width: 1 }}
                  alignItems="center"
                  justifyContent="center"
                  gap={2}
                >
                  <Box
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                      borderRadius: 1.5,
                      display: 'flex',
                      backgroundColor,
                      width: 48,
                      height: 48,
                    }}
                  >
                    <Box
                      component="img"
                      alignItems="center"
                      src={walletIcon}
                      justifyContent="center"
                      sx={{
                        width: 24,
                        height: 24,
                      }}
                    />
                  </Box>
                  <Stack flexGrow={1} direction="column" alignItems="flex-start">
                    <Typography
                      variant="body1"
                      fontSize={16}
                      fontWeight="fontWeightSemiBold"
                      sx={{ textTransform: 'capitalize', color: 'common.black' }}
                    >
                      {currency?.alternativeName}
                    </Typography>
                    <Typography
                      variant="caption"
                      fontSize={14}
                      fontWeight="fontWeightMedium"
                      sx={{ textTransform: 'capitalize', color: 'grey.700' }}
                    >
                      {name}
                    </Typography>
                  </Stack>
                </Stack>
              }
              sx={{
                border: '1px solid #BFCDC7',
                borderRadius: '12px',
                height: '80px',
                flexDirection: 'row-reverse',
                justifyContent: 'space-between',
                my: 1,
                mx: 0,
                p: 3,
              }}
            />
          );
        })}
      </FormGroup>
    </FormControl>
  );

  const handleContinue = () => {
    formData.onUpdate('wallets', selectedWallets);
    handleNext('selectAddress');
  };

  return (
    <Stack
      direction="column"
      alignItems="center"
      justifyContent="center"
      spacing={2}
      sx={{ height: 1, width: 1, px: 2, pb: 3, pt: 2 }}
    >
      <Typography
        variant="subtitle1"
        sx={{
          color: 'grey.600',
          whiteSpace: 'pre',
          fontWeight: 'fontWeightMedium',
          textAlign: 'center',
        }}
        fontSize={16}
      >
        {`Selecciona una o varias billeteras para\ngestionar los pagos de locales`}
      </Typography>
      {walletsLoading ? (
        <Stack
          direction="column"
          alignItems="center"
          justifyContent="center"
          spacing={1}
          flexGrow={1}
          sx={{ width: 1, mt: 4 }}
        >
          {Array.from({ length: 4 }).map((_, index) => (
            <WalletSkeleton height={80} key={index} sx={{ my: 1 }} />
          ))}
        </Stack>
      ) : (
        renderPaymentMethods
      )}
      <Stack direction="column" alignItems="center" sx={{ mt: 6 }} spacing={1} width="100%">
        <Button
          onClick={handleContinue}
          fullWidth
          size="large"
          color="primary"
          variant="contained"
          sx={{ width: 1 }}
        >
          Continuar
        </Button>
        <Button
          component={RouterLink}
          href={paths.home.wallets.new}
          startIcon={<Iconify icon="ic:round-plus" sx={{ color: 'green.primary' }} />}
          fullWidth
          size="large"
          color="primary"
          variant="outlined"
          sx={{ width: 1, color: 'green.dark', fontWeight: 500 }}
        >
          Abrir billetera
        </Button>
      </Stack>
    </Stack>
  );
}

SelectWallets.propTypes = {
  currentStore: PropTypes.object,
};
