import axios from 'axios';
import { AUTH_API } from 'src/config-global';

const axiosAuthInstance = axios.create({ baseURL: AUTH_API });

axiosAuthInstance.interceptors.response.use(
  (response) => response.data,
  (error) => {
    // Handle error globally or rethrow for individual handling
    const errorResponse = error.response && error.response.data;
    console.error('Error in API request:', errorResponse || 'Something went wrong');
    return Promise.reject(errorResponse || 'Something went wrong');
  }
);

export default axiosAuthInstance;

// ----------------------------------------------------------------------

export const endpoints = {
  auth: {
    login: '/v1/auth/login',
    logout: '/v1/auth/logout',
    otp: '/v1/auth/otp',
    refresh: '/v1/auth/refresh',
    getUser: '/v1/auth',
  },
};
