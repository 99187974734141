// utils
import axiosFeaturesInstance, { endpoints } from './axios';

const list = async () => {
  try {
    const response = await axiosFeaturesInstance.get(endpoints.cashin.list);
    return response;
  } catch (error) {
    console.error('Error during list cashin:', error);
    throw error;
  }
};

const updateAlias = async (alias) => {
  try {
    const response = await axiosFeaturesInstance.post(endpoints.cashin.updateAlias, {alias});
    return response;
  } catch (error) {
    console.error('Error updating alias:', error);
    throw error;
  }
};

export const cashin = { list, updateAlias };
