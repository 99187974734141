import { isValidToken, jwtDecode } from 'src/commons/contexts/auth/context/jwt/utils';
import { services } from 'src/services/api';

async function fetchPublicVapidKey() {
  try {
    const response = await services.features.webPush.getPublicVapidKey();
    if (response.success) return response.data;
    return false;
  } catch (error) {
    console.log('service worker notification error - could not fetch public key');
    return false;
  }
}

async function saveSubscription(subscription, userId, token) {
  try {
    if (!subscription || !userId || !token) {
      console.log('service worker error - no subscription or userId');
      return;
    }
    console.log('SEND SUBSCRIPTION TO BACKEND START');
    const subscriptionBody = {
      endpoint: subscription.endpoint,
      keys: subscription.keys,
      userId,
    };
    console.log('subscriptionBody', subscriptionBody);
    await services.features.webPush.create(subscriptionBody);
    console.log('SEND SUBSCRIPTION TO BACKEND END');
  } catch (error) {
    console.log('service worker error saving subscription');
  }
}

function getToken() {
  const STORAGE_KEY = 'accessToken';
  const accessToken = localStorage.getItem(STORAGE_KEY);
  console.log({ accessToken });
  if (accessToken && isValidToken(accessToken)) return accessToken;
  console.log('service worker error - no valid token');
  return false;
}

function decodeToken(accessToken) {
  const token = jwtDecode(accessToken);
  console.log({ token });
  return token;
}

async function getUser(token) {
  try {
    const res = await services.api.auth.getUser(token.cuit);
    const user = { ...res?.data, lastname: token?.lastname };
    console.log({ user });
    return user;
  } catch (error) {
    console.log(error);
    console.log('service worker error fetching user');
    return false;
  }
}

function urlBase64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; i += 1) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

export async function subscribeToNotifications() {
  const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

  // Se busca el objeto registration de nuestro service worker
  const registration = await navigator.serviceWorker.getRegistration(swUrl);
  console.log({ registration });

  // Se busca el token
  const token = getToken();
  if (!token) return;
  console.log('TOKEN', { token });

  // Se decodifica el token
  const decodedToken = decodeToken(token);
  if (!decodedToken) return;

  // Se busca al usuario usando el token decodificado
  const user = await getUser(decodedToken);
  if (!user) return;

  console.log('READY SW START');
  // Se espera a que este listo el service worker
  const ready = await navigator.serviceWorker.ready;
  console.log({ ready });
  console.log('READY SW END');

  // Se busca la publickey del backend
  console.log('GET PUBLIC KEY START');
  const publicKey = await fetchPublicVapidKey();
  console.log({ publicKey });
  if (!publicKey) return;
  console.log('GET PUBLIC KEY END');

  let pushSubscription = await registration.pushManager.getSubscription();
  console.log({ pushSubscription });

  if (pushSubscription) {
    const parsedPushSubscription = JSON.parse(JSON.stringify(pushSubscription));
    await saveSubscription(parsedPushSubscription, user?.id, token);
    return;
  }

  console.log('PUSH SUBSCRIPTION START');
  // Se suscribe al usuario a las notificaciones push
  pushSubscription = await registration.pushManager.subscribe({
    userVisibleOnly: true,
    applicationServerKey: urlBase64ToUint8Array(publicKey),
  });
  console.log('PUSH SUBSCRIPTION END');

  const parsedSubscription = JSON.parse(JSON.stringify(pushSubscription));

  console.log({ parsedSubscription });
  await saveSubscription(parsedSubscription, user?.id, token);
}
