import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-extraneous-dependencies
import GoogleMapReact from 'google-map-react';
// components
import { Skeleton } from '@mui/material';
import Iconify from 'src/commons/components/iconify';

const Marker = () => (
  <Iconify icon="fa6-solid:map-pin" width={42} sx={{ color: 'green.primary', zIndex: 99 }} />
);

const AddressMap = ({ latitude, longitude, isLoading = false }) => {
  const center = {
    lat: latitude,
    lng: longitude,
  };

  return (
    <>
      {!isLoading && (
        <GoogleMapReact
          bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY }}
          center={center}
          defaultZoom={17}
        >
          <Marker lat={latitude} lng={longitude} />
        </GoogleMapReact>
      )}
      {isLoading && (
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="100%"
          height="30vh"
          sx={{ bgcolor: 'grey.100' }}
        />
      )}
    </>
  );
};

AddressMap.propTypes = {
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  isLoading: PropTypes.bool,
};

export default AddressMap;
