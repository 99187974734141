import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/commons/contexts/auth/guard';
// components
import { LoadingScreen } from 'src/commons/components/loading-screen';
import WrapperLayout from 'src/commons/layouts/wrapper/layout';
// paths
import { paths } from '../paths';

// ----------------------------------------------------------------------

// OVERVIEW
const IndexPage = lazy(() => import('src/features/update-alias'));

// ----------------------------------------------------------------------

export const updateAliasRoutes = [
  {
    path: '/update-alias',
    element: (
      <AuthGuard>
        <WrapperLayout title="Alias" path={paths.home.chargeYourPalta}>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </WrapperLayout>
      </AuthGuard>
    ),
    children: [{ element: <IndexPage />, index: true }],
  },
];
