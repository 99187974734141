import { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import Alert from '@mui/material/Alert';
import FormProvider, { RHFTextField, RHFSelect } from 'src/commons/components/hook-form';
// services
import { services } from 'src/services/api';
// components
import { useSnackbar } from 'src/commons/components/snackbar';
// assets
import Header from 'src/commons/layouts/wrapper/header';

// ----------------------------------------------------------------------
const phoneRegExp = /\d{1,14}$/;

export default function ChangePhone() {
  const { enqueueSnackbar } = useSnackbar();
  const sessionStoredUser = JSON.parse(sessionStorage.getItem('user'));
  const [countdownEmail, setCountdownEmail] = useState(0);
  const [isCodeSentEmail, setIsCodeSentEmail] = useState(false);
  const [remainingAttemptsEmail, setRemainingAttemptsEmail] = useState(60);

  const VerifySchema = Yup.object().shape({
    emailOtp: Yup.string()
      .matches(/^\d{6}$/, 'Código de OTP inválido')
      .required('Código de OTP de Email es obligatorio'),
    phone: Yup.string()
      .matches(phoneRegExp, 'Número de teléfono inválido')
      .required('Campo requerido'),
  });

  const defaultValues = {
    emailOtp: '',
    phone: '',
    prefix: '+54',
  };

  const methods = useForm({
    mode: 'onChange',
    delayError: 500,
    resolver: yupResolver(VerifySchema),
    defaultValues,
  });

  const {
    handleSubmit,
    watch,
    formState: { isSubmitting, isValid },
  } = methods;

  const prefix = watch('prefix');

  const [errorMsg, setErrorMsg] = useState('');

  const onSubmit = handleSubmit(async (data) => {
    try {
      let phoneNumber = data.phone;
      if (!phoneNumber.startsWith('9')) {
        phoneNumber = `9${phoneNumber}`;
      }
      phoneNumber = `${prefix}${phoneNumber}`;
      const res = await services.features.more.changePhone(data?.emailOtp, phoneNumber);

      if (res.success) {
        enqueueSnackbar('Numero de telefono cambiado con exito!');
      }
    } catch (error) {
      console.error(error);
      setErrorMsg(error.message);
    }
  });

  useEffect(() => {
    let intervalIdEmail;

    if (isCodeSentEmail) {
      setCountdownEmail(60);

      intervalIdEmail = setInterval(() => {
        setCountdownEmail((prevCountdown) => prevCountdown - 1);
      }, 1000);

      setTimeout(() => {
        clearInterval(intervalIdEmail);
        setIsCodeSentEmail(false);
      }, 60000);
    }

    return () => {
      clearInterval(intervalIdEmail);
    };
  }, [isCodeSentEmail]);

  const sendOtp = async (isEmail) => {
    try {
      const res = await services.api.auth.otp(sessionStoredUser.id, isEmail);
      if (res.success) return res;
    } catch (error) {
      console.error(error);
      setErrorMsg(error.message);
    }

    return null;
  };

  const handleSendCodeEmail = async () => {
    if (!isCodeSentEmail && remainingAttemptsEmail > 0) {
      setIsCodeSentEmail(true);
      setRemainingAttemptsEmail((prevRemainingAttempts) => prevRemainingAttempts - 1);
      const emailOtp = await sendOtp(true);
      if (emailOtp.success) {
        enqueueSnackbar('Código enviado con éxito!');
      }
    }
  };

  const sendCodeEmail = (
    <InputAdornment position="end">
      <Button
        variant="text"
        color="primary"
        sx={{
          cursor: 'pointer',
        }}
        disabled={isCodeSentEmail || remainingAttemptsEmail === 0}
        onClick={handleSendCodeEmail}
      >
        {isCodeSentEmail ? `Enviar ${countdownEmail.toString().padStart(2, '0')}` : 'Enviar código'}
      </Button>
    </InputAdornment>
  );

  const renderForm = (
    <>
      <Header title="Cambia tu Numero" />
      <Typography variant="h6" sx={{ px: 2, fontWeight: 500, color: 'grey.500' }}>
        Para verificar tu identidad necesitamos que nos envies un codigo que te llegara a tu mail
      </Typography>
      <Stack px={2} mt={5} sx={{ pt: 4, height: 1 }} alignItems="center">
        <Stack sx={{ width: 1 }} spacing={3} flexGrow={1} alignItems="center">
          {!!errorMsg && <Alert severity="error">{errorMsg}</Alert>}
          <RHFTextField
            variant="outlined"
            name="emailOtp"
            fullWidth
            placeholder="Código Email"
            inputProps={{
              inputMode: 'numeric',
              maxLength: 6,
            }}
            InputProps={{
              endAdornment: sendCodeEmail,
            }}
          />
          <Stack direction="row" sx={{ width: 1 }} spacing={2} alignItems="center">
            <RHFSelect sx={{ width: '30%' }} name="prefix" label="Prefijo">
              <MenuItem value="+54">+54</MenuItem>
            </RHFSelect>
            <RHFTextField
              InputProps={{
                startAdornment: <InputAdornment position="start">{prefix}</InputAdornment>,
              }}
              sx={{ mt: 2.5 }}
              helperText={<Typography variant="caption">Sin 15 (Ej:33433023)</Typography>}
              type="tel"
              name="phone"
              inputProps={{ inputMode: 'tel' }}
              label="Tu número de teléfono"
            />
          </Stack>
        </Stack>
        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          disabled={!isValid}
          variant="contained"
          sx={{ width: 1 }}
          loading={isSubmitting}
        >
          Confirmar
        </LoadingButton>
      </Stack>
    </>
  );

  return (
    <FormProvider fullHeight methods={methods} onSubmit={onSubmit}>
      {renderForm}
    </FormProvider>
  );
}
