import { createContext, useContext } from 'react';

// ----------------------------------------------------------------------

export const FormContext = createContext({});

export const useFormContext = () => {
  const context = useContext(FormContext);

  if (!context) throw new Error('useFormContext must be use inside FormProvider');

  return context;
};
