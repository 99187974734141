// utils
import axiosFeaturesInstance, { endpoints } from './axios';

const list = async (startDate, endDate) => {
  try {
    const response = await axiosFeaturesInstance(endpoints.wallets.list);
    return response;
  } catch (error) {
    console.error('Error during wallets list:', error);
    throw error;
  }
};

const getById = async (id) => {
  try {
    const response = await axiosFeaturesInstance(`${endpoints.wallets.list}/${id}`);
    return response;
  } catch (error) {
    console.error('Error during wallet list:', error);
    throw error;
  }
};

const listMovements = async (id) => {
  try {
    const response = await axiosFeaturesInstance(`${endpoints.wallets.list}/movements/${id}`);
    return response;
  } catch (error) {
    console.error('Error during movements wallet list:', error);
    throw error;
  }
};

const create = async (data) => {
  try {
    const response = await axiosFeaturesInstance.post(endpoints.wallets.list, data);
    return response;
  } catch (error) {
    console.error('Error during create wallet:', error);
    throw error;
  }
};

const hideWallet = async (walletId, hide = false) => {
  try {
    const response = await axiosFeaturesInstance.patch(
      `${endpoints.wallets.hideWallet}/${walletId}`,
      {
        hide,
      }
    );
    return response;
  } catch (error) {
    console.error('Error during hide wallet:', error);
    throw error;
  }
};

const editWalletName = async (walletId, name) => {
  try {
    const response = await axiosFeaturesInstance.patch(
      `${endpoints.wallets.editWalletName}/${walletId}`,
      {
        name,
      }
    );
    return response;
  } catch (error) {
    console.error('Error during edit wallet name:', error);
    throw error;
  }
};

export const wallets = { list, getById, listMovements, create, hideWallet, editWalletName };
