import { auth } from './auth';
import {
  onboarding,
  history,
  notifications,
  operations,
  wallets,
  storesAndPromos,
  promotions,
  stores,
  cashin,
  googleMaps,
  phoneTopup,
  qrPayment,
  sendMoney,
  more,
  servicePayment,
  myStores,
  configs,
  webPush,
} from './features';

export const services = {
  api: { auth },
  features: {
    onboarding,
    notifications,
    operations,
    history,
    wallets,
    storesAndPromos,
    promotions,
    stores,
    cashin,
    phoneTopup,
    qrPayment,
    sendMoney,
    more,
    servicePayment,
    myStores,
    configs,
    webPush,
  },
  googleMaps,
};
