// utils
import axiosFeaturesInstance, { endpoints } from './axios';

const list = async () => {
  try {
    const response = await axiosFeaturesInstance.get(endpoints.promotions.list);
    return response;
  } catch (error) {
    console.error('Error during list promotions:', error);
    throw error;
  }
};

export const promotions = { list };
