// utils
import axiosFeaturesInstance, { endpoints } from './axios';

const list = async (startDate, endDate) => {
  try {
    const queryParams = new URLSearchParams();
    if (startDate) queryParams.append('startDate', startDate);
    if (endDate) queryParams.append('endDate', endDate);
    const url = `${endpoints.history.list}?${queryParams.toString()}`;

    const response = await axiosFeaturesInstance.get(url);
    return response;
  } catch (error) {
    console.error('Error during list history:', error);
    throw error;
  }
};


export const history = { list };
